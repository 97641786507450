<template>
  <div class="event-time-col-cont">
    <div class="event-time-col" v-if="show_component">
      <h4>
        <div
          class="icon-back"
          @click="$emit('slot-selector-back-clicked', 1)"
        ></div>
        {{ __("Select a Time", "wpcal") }}
      </h4>
      <div class="selected-date">
        {{ selected_date | wpcal_format_fw_fm_dth_y }}
      </div>
      <ul class="time-slot-selector">
        <li
          v-for="(option, index) in day_available_slots"
          :key="index"
          class="wpcal_slot"
          :class="{
            confirmation: confirm_index === index,
            cf: confirm_index === index
          }"
          :date-slot-from-time="option.from_time"
          @click="show_confirm(index)"
        >
          <a>{{ option.from_time | wpcal_unix_to_time(tz, time_format) }}</a>
          <button
            v-if="confirm_index === index"
            @click="confirmed_slot(option)"
          >
            {{ __("Confirm", "wpcal") }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "booking_slot",
    event: "change-selected-slot"
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    selected_date: {
      required: true
    },
    availabile_slots_details: {
      type: Object,
      required: true
    },
    booking_slot: {
      required: true
    },
    form: {
      type: Object
    }
  },
  data() {
    return {
      confirm_index: null
    };
  },
  computed: {
    show_component() {
      return this.show && this.selected_date instanceof Date;
    },
    formatted_selected_date() {
      return this.$options.filters.wpcal_format_std_date(this.selected_date);
    },
    day_available_slots() {
      if (
        typeof this.availabile_slots_details.slots[
          this.formatted_selected_date
        ] === "undefined"
      ) {
        return [];
      }
      return this.availabile_slots_details.slots[this.formatted_selected_date]
        .slots;
    }
  },
  methods: {
    show_confirm(index) {
      this.confirm_index = index;
    },
    confirmed_slot(slot) {
      //this.booking_slot = slot;
      this.$emit("change-selected-slot", slot);
      this.$emit("slot-selected", 1);
    },
    may_set_confirm_index() {
      if (this.confirm_index || !this.form.booking_slot) {
        return;
      }
      for (let slot_index in this.day_available_slots) {
        if (
          this.day_available_slots[slot_index].from_time ==
            this.form.booking_slot.from_time &&
          this.day_available_slots[slot_index].to_time ==
            this.form.booking_slot.to_time
        ) {
          this.confirm_index = Number(slot_index);

          this.scroll_to_within_cont(
            '.wpcal_slot[date-slot-from-time="' +
              this.day_available_slots[slot_index].from_time +
              '"]',
            ".time-slot-selector"
          );
          break;
        }
      }
    }
  },
  mounted() {
    this.may_set_confirm_index();
  },
  watch: {
    formatted_selected_date() {
      this.confirm_index = null; //this is clear index when date selected
    },
    availabile_slots_details() {
      //this.confirm_index = null; //this is clear index when availabile_slots_details changes
    }
  }
};
</script>
