<template>
  <div class="event-preset-cont">
    <div class="event-preset">
      <div>
        <div class="inviter-pic">
          <img :src="service_admin_details.profile_picture" />
        </div>
        <div class="inviter-name">{{ service_admin_details.display_name }}</div>
        <div class="event-type-name">{{ service_details.name }}</div>
        <div class="event-type-duration">
          {{ service_details.duration | mins_to_str }}
        </div>
        <!-- <div class="event-type-location" v-if="service_details.location">
          {{ service_details.location }}
        </div> -->
        <LocationAdvanced
          type="display_booking_left_info_section"
          :locations="service_details.locations"
        />
        <div
          class="event-type-description"
          style="font-size: 13px; margin-top: 10px;"
          v-if="service_details.descr"
        >
          <!-- for using pre -->
          <!-- eslint-disable-next-line -->
          <span class="pre">{{ service_details.descr }}</span>
        </div>
      </div>
      <BookingOldInfo
        :old_booking="old_booking"
        :booking_step="booking_step"
        v-if="
          booking_type == 'reschedule' &&
            old_booking &&
            typeof old_booking === 'object' &&
            old_booking.hasOwnProperty('unique_link')
        "
      />
    </div>
  </div>
</template>

<script>
import BookingOldInfo from "@/components/user/BookingOldInfo.vue";
import LocationAdvanced from "@/components/LocationAdvanced.vue";

export default {
  components: {
    BookingOldInfo,
    LocationAdvanced
  },
  props: {
    booking_type: {
      type: String
    },
    service_details: {
      type: Object
    },
    service_admin_details: {
      type: Object
    },
    old_booking: {
      type: Object
    },
    booking_step: {
      type: String
    }
  }
};
</script>
