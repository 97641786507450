import Vue from "vue";

import EventBus from "@/utils/event_bus.js";

import { isEmpty as _isEmpty, cloneDeep as _cloneDeep } from "lodash-es";
import { date_parse } from "@/utils/common_func.js";

import AdminHeaderRight from "@/components/admin/AdminHeaderRight.vue";

Vue.mixin({
  components: {
    AdminHeaderRight //admin only
  },
  data() {
    return {
      notification_system: {
        validation_errors: {
          target: ".validation_errors_cont",
          timeout: false,
          zindex: "auto"
        },
        error: {
          resetOnHover: true
        }
      }
    };
  },
  computed: {
    client_end() {
      return this.$store.getters.get_client_end;
    },
    tz() {
      return this.$store.getters.get_tz;
    },
    time_format() {
      return this.$store.getters.get_time_display_format;
    },
    document_title_final() {
      if (typeof this.get_document_title === "function") {
        return this.get_document_title();
      } else if (this.document_title) {
        return this.document_title;
      }
      return "";
    },
    event_bus() {
      return EventBus;
    }
  },
  methods: {
    log(...e) {
      console.log(...e);
    },
    _isEmpty(v) {
      return _isEmpty(v);
    },
    clone_deep(v) {
      return _cloneDeep(v);
    },
    ref_focus(ref_name) {
      setTimeout(() => {
        //this.log("focus:" + ref_name);
        this.$refs[ref_name].focus();
      }, 100);
    },
    ref_click(ref_name) {
      setTimeout(() => {
        //this.log("click:" + ref_name);
        this.$refs[ref_name].click();
      }, 100);
    },
    date_parse(date_str) {
      let parsed = date_parse(date_str);
      return parsed;
    },
    //Notification related
    notify_single_action_result(single_action_result, notify_options = {}) {
      if (
        !single_action_result ||
        typeof single_action_result !== "object" ||
        !single_action_result.hasOwnProperty("status")
      ) {
        return;
      }

      if (single_action_result.status === "success") {
        if (notify_options?.dont_notify_success) {
          return;
        }
        let success_msg = notify_options.success_msg
          ? notify_options.success_msg
          : "Succesfully done.";
        let success_title = notify_options.success_title
          ? notify_options.success_title
          : "Ok";

        this.$toast.success(success_msg, success_title, notify_options);
      } else if (single_action_result.status === "error") {
        if (single_action_result.error === "validation_errors") {
          return this.notify_validation_errors(
            single_action_result,
            notify_options
          );
        }
        let error_msg =
          single_action_result.hasOwnProperty("error_msg") &&
          single_action_result.error_msg
            ? single_action_result.error_msg
            : "Error while processing the request";
        let error_title = "Oops";

        let error_notify_options = Object.assign(
          {},
          this.notification_system.error,
          notify_options.error
        );

        this.$toast.error(error_msg, error_title, error_notify_options);
      }
    },
    notify_validation_errors(single_action_result, notify_options = {}) {
      let this_notify_options = Object.assign(
        {},
        this.notification_system.validation_errors,
        notify_options.validation_errors
      );

      let error_msg =
        single_action_result.hasOwnProperty("error_msg") &&
        single_action_result.error_msg
          ? single_action_result.error_msg
          : "";
      let error_title = "Oops";

      let error_list = [];
      if (
        single_action_result.hasOwnProperty("error_data") &&
        single_action_result.error_data &&
        typeof single_action_result.error_data === "object"
      ) {
        for (let key in single_action_result.error_data) {
          for (let key_level2 in single_action_result.error_data[key]) {
            error_list.push(single_action_result.error_data[key][key_level2]);
          }
        }
      }

      if (error_list.length > 0) {
        error_msg += "<ul><li>" + error_list.join("</li><li>") + "</li></ul>";
      }

      this.clear_validation_errors_cont();
      this.$toast.error(error_msg, error_title, this_notify_options);
      this.scroll_to_first_validation_error(this_notify_options.target);
    },
    clear_validation_errors_cont() {
      if (!this.notification_system.validation_errors.target) {
        return false;
      }
      let error_cont_selector = this.notification_system.validation_errors
        .target;
      let errors_cont = document.querySelector(error_cont_selector);
      if (errors_cont) {
        errors_cont.innerHTML = ""; //reset exisiting
      }
    },
    scroll_to_first_validation_error(query = ".form-row.has-error") {
      this.scroll_to(query);
    },
    scroll_to(query, adjust_y = -50, check_view_port = false) {
      if (this.client_end == "admin") {
        adjust_y = adjust_y - 20; //go little more top - because admin bar
      }
      this.$nextTick(() => {
        if (!query) {
          return false;
        }
        let elmnt = document.querySelector(query);

        if (elmnt) {
          if (check_view_port && this.is_element_top_in_view_port(elmnt)) {
            return;
          }
          let top = elmnt.getBoundingClientRect().top + adjust_y;

          window.scrollBy({
            top: top,
            left: 0,
            behavior: "smooth"
          });
        }
      });
    },
    scroll_to_if_not_in_view_port(query, adjust_y = -50) {
      return this.scroll_to(query, adjust_y, true);
    },
    is_element_top_in_view_port(el) {
      let rect = el.getBoundingClientRect();

      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.top <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    is_element_in_view_port(el) {
      let rect = el.getBoundingClientRect();

      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    scroll_to_within_cont(element_selector, container_selector) {
      const ele = document.querySelector(element_selector);
      const container = document.querySelector(container_selector);
      if (!ele || !container) {
        return;
      }
      const eleTop = ele.offsetTop;
      const eleBottom = eleTop + ele.clientHeight;

      const containerTop = container.scrollTop;
      const containerBottom = containerTop + container.clientHeight;

      if (eleTop < containerTop) {
        // Scroll to the top of container
        container.scrollTop -= containerTop - eleTop;
      } else if (eleBottom > containerBottom) {
        // Scroll to the bottom of container
        container.scrollTop +=
          eleBottom - containerBottom + container.clientHeight / 2;
      }
    },
    on_click_outside(selector, call_back) {
      let element = document.querySelector(selector);
      const outsideClickListener = event => {
        if (element && !element.contains(event.target)) {
          if (isVisible(element)) {
            if (call_back) {
              call_back();
            }
          }
          removeClickListener();
        }
      };

      const removeClickListener = () => {
        document.removeEventListener("click", outsideClickListener);
      };

      const isVisible = elem =>
        !!elem &&
        !!(
          elem.offsetWidth ||
          elem.offsetHeight ||
          elem.getClientRects().length
        );

      document.addEventListener("click", outsideClickListener);
    },
    show_delete_highlight(del_highlight_index, index) {
      return this[del_highlight_index] === index;
    },
    set_mouseover_on_delete(del_highlight_index, index, is_hover) {
      if (is_hover) {
        this[del_highlight_index] = index;
      } else {
        this[del_highlight_index] = null;
      }
    },
    get_maxlength_from_validation(element_details) {
      let default_return = "";
      if (!element_details.hasOwnProperty("$params")) {
        return default_return;
      }

      if (!element_details.$params) {
        return default_return;
      }
      for (let key in element_details.$params) {
        if (
          element_details.$params[key].hasOwnProperty("max") &&
          element_details.$params[key].max &&
          element_details.$params[key].hasOwnProperty("type") &&
          element_details.$params[key].type == "maxLength"
        ) {
          return element_details.$params[key].max;
        }
      }
      return default_return;
    },
    on_click_if_same_page_target_router_push(event, router_path) {
      //contextmenu open is new window will not be considered as click, no worries
      //ctrl + click open in new tab, shift + click open in new window
      // Functionality is if opening in new window leave it, if not open using router push. The element <a> with href is tested

      // this.log( 'event.ctrlKey', event.ctrlKey, 'event.shiftKey', event.shiftKey, 'event.metaKey', event.metaKey);

      if (
        event.ctrlKey ||
        event.shiftKey ||
        event.metaKey || // apple
        (event.button && event.button == 1) // middle click, >IE9 + everyone else
      ) {
        //do it normal browser way
        return;
      }
      if (router_path) {
        event.preventDefault();
        this.$router.push(router_path);
      }
    }
  }
});
