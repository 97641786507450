<template>
  <div
    v-if="maxlength"
    :class="{ [char_left_class]: true }"
    :style="{ color: char_left_class }"
    style="text-align: right; margin-top: 5px; margin-bottom: 10px; float: right;"
  >
    {{ char_left }} {{ __("char remaining", "wpcal") }}
  </div>
</template>

<script>
export default {
  props: {
    maxlength: {
      type: Number
    },
    input_text: {
      type: String
    }
  },
  computed: {
    char_left() {
      return this.maxlength - this.input_text.length;
    },
    char_left_class() {
      //   let char_left_percent = this.char_left / this.maxlength;
      //   if (char_left_percent <= 0.1 && char_left_percent > 0.05) {
      //     return "orange";
      //   } else if (char_left_percent <= 0.05) {
      //     return "red";
      //   }
      if (this.char_left <= 25 && this.char_left > 10) {
        return "orange";
      } else if (this.char_left <= 10) {
        return "red";
      }
      return "inherit";
    }
  }
};
</script>
