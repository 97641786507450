var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"font-size":"15px","margin-top":"20px"}},[_c('div',[_c('div',{staticStyle:{"text-decoration":"underline"}},[_vm._v(" "+_vm._s(_vm.__("Original Booking Info", "wpcal"))+" ")]),_c('div',{class:{ striked: _vm.booking_step == 2 },domProps:{"innerHTML":_vm._s(
        _vm.$options.filters.wpcal_unix_to_from_to_time_full_date_day_tz(
          _vm.old_booking.booking_from_time,
          _vm.old_booking.booking_to_time,
          _vm.tz,
          _vm.time_format
        ) + '.'
      )}})])])}
var staticRenderFns = []

export { render, staticRenderFns }