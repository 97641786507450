import axios from "axios";
import qs from "qs";

// import admin_store from "@/store/admin.js";
let admin_store = null;

// (async () => {
async function may_init_admin_store() {
  if (window.__wpcal_client_end == "admin") {
    let admin_store_module = await import("@/store/admin.js");
    admin_store = admin_store_module.default;
  }
}
may_init_admin_store();

axios.interceptors.request.use(
  config => {
    const new_config = config;
    //console.log(config);

    let options = {};
    let show_loading_indicator = true;
    if (
      new_config.hasOwnProperty("params") &&
      new_config.params.hasOwnProperty("_remove_options_before_call")
    ) {
      options = Object.assign(
        options,
        new_config.params._remove_options_before_call
      );
      delete new_config.params._remove_options_before_call;
    }

    if (typeof new_config.data === "object" && new_config.data) {
      // adding nonce to all admin ajax calls STARTS here
      if (window.__wpcal_client_end == "admin" && admin_store) {
        let admin_main_nonce = admin_store.getters.get_admin_main_nonce;
        new_config.data["wpcal_admin_main_nonce"] = admin_main_nonce;
      }
      // adding nonce to all admin ajax calls Ends here

      new_config.data = qs.stringify(new_config.data);
    }

    if (options.hasOwnProperty("background_call") && options.background_call) {
      show_loading_indicator = false;
    }

    // spinning start to show global loading indicator
    let client_end = window.__wpcal_client_end;
    if (show_loading_indicator) {
      document
        .getElementById("wpcal_" + client_end + "_app")
        ?.classList.add("loading-indicator");
    }

    new_config.__custom_options = options;

    return new_config;
  },
  error =>
    // Do something with request error
    Promise.reject(error)
);

axios.interceptors.response.use(
  async response => {
    // Disabling below code to get is_debug, alternate solution coded
    // let store_all = await import(
    //   "@/store/" + window.__wpcal_client_end + ".js"
    // );
    // let store = store_all.default;

    // spinning hide global loading indicator
    let client_end = window.__wpcal_client_end;
    document
      .getElementById("wpcal_" + client_end + "_app")
      ?.classList.remove("loading-indicator");

    if (response.status == 200 && typeof response.data === "string") {
      response.data = wpcal_clean_and_parse_json_response(response.data);

      if (response.data?.wpcal_main_error) {
        let error_message = response.data?.wpcal_main_error_msg
          ? response.data.wpcal_main_error_msg
          : 'Something went wrong, Click "Ok" to reload the page.';
        let is_ok = confirm(error_message);
        if (is_ok) {
          location.reload();
        }
      }
    }
    if (window.wpcal_ajax.is_debug) {
      console.log(response.data);
    }

    return response;
  },
  error => {
    // spinning hide global loading indicator
    let client_end = window.__wpcal_client_end;
    document
      .getElementById("wpcal_" + client_end + "_app")
      ?.classList.remove("loading-indicator");

    return Promise.reject(error);
  }
);

function wpcal_clean_response(response_data) {
  //return substring closed by <wpcal_response> and </wpcal_response>
  return response_data
    .split("<wpcal_response>")
    .pop()
    .split("</wpcal_response>")
    .shift();
}

function wpcal_clean_and_parse_json_response(response_data) {
  response_data = wpcal_clean_response(response_data);
  try {
    return JSON.parse(response_data);
  } catch (e) {
    console.log(e);
    console.log(
      "Unexpected HTTP response(JSON Parse Error) - Response text:\n",
      response_data
    );
    return "JSON_PARSE_ERROR";
  }
}
// })();
