<template>
  <div>
    <modal
      name="booking_cancel_confirm_modal"
      style="z-index:9999;"
      :height="'auto'"
      :adaptive="true"
    >
      <div class="modal_close_cont">
        <button @click="$modal.hide('booking_cancel_confirm_modal')"></button>
      </div>
      <div style="font-size: 16px; padding: 20px;">
        <strong
          style="font-size: 18px; line-height: 1.2em; margin-bottom: 20px;display: block;"
        >
          {{ __("Are you sure you want to cancel this meeting?", "wpcal") }}
        </strong>
        <span
          v-html="
            $options.filters.wpcal_unix_to_from_to_time_full_date_day_tz(
              booking_details.booking_from_time,
              booking_details.booking_to_time,
              tz,
              time_format
            ) + '.'
          "
        >
        </span>
        <br />
        <div v-if="client_end == 'admin'">
          with {{ booking_details.invitee_name }} ({{
            booking_details.invitee_email
          }})
        </div>
        <div style="margin-top: 10px;">
          {{ __("Reason for cancellation:", "wpcal") }}
        </div>
        <div class="wpc-form-row">
          <textarea v-model="cancel_reason_final" rows="2" />
          <div v-if="client_end == 'admin'" class="txt-help">
            {{
              T__(
                "Cancellation reason will be communicated to invitee.",
                "wpcal"
              )
            }}
          </div>
        </div>
        <div class="booking-cancel-action-btns">
          <button
            class="wpc-btn primary lg"
            style="margin:10px 10px 10px 0; padding-left:10px; padding-right:10px;"
            type="button"
            :disabled="!cancel_booking_btn_is_enabled"
            @click="$emit('cancel-booking', booking_details)"
          >
            {{ __("Yes, cancel.", "wpcal") }}
          </button>
          <button
            class="wpc-btn primary lg"
            style="margin:10px 10px 10px 0; padding-left:10px; padding-right:10px;"
            type="button"
            @click="$modal.hide('booking_cancel_confirm_modal')"
          >
            {{ __("No, don't.", "wpcal") }}
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "BookingCancelModal",
  model: {
    prop: "cancel_reason",
    event: "changed-cancel-reason"
  },
  props: {
    cancel_reason: {
      type: String
    },
    booking_details: {
      type: Object
    },
    cancel_booking_btn_is_enabled: {
      type: Boolean
    }
  },
  computed: {
    cancel_reason_final: {
      get: function() {
        return this.cancel_reason;
      },
      set: function(new_value) {
        this.$emit("changed-cancel-reason", new_value);
      }
    }
  }
};
</script>
<style>
#wpcal_user_app .booking-cancel-action-btns button {
  padding: 10px 20px !important;
}
#wpcal_user_app .booking-cancel-action-btns button:last-child {
  background-color: rgba(var(--accentClrRGB), 0.1) !important;
  border: 1px solid rgba(var(--accentClrRGB), 1);
  color: rgba(var(--accentClrRGB), 1) !important;
}
#wpcal_user_app .booking-cancel-action-btns button:first-child {
  background-color: #e84653 !important;
}
</style>
