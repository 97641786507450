var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('modal',{staticStyle:{"z-index":"9999"},attrs:{"name":"booking_cancel_confirm_modal","height":'auto',"adaptive":true}},[_c('div',{staticClass:"modal_close_cont"},[_c('button',{on:{"click":function($event){return _vm.$modal.hide('booking_cancel_confirm_modal')}}})]),_c('div',{staticStyle:{"font-size":"16px","padding":"20px"}},[_c('strong',{staticStyle:{"font-size":"18px","line-height":"1.2em","margin-bottom":"20px","display":"block"}},[_vm._v(" "+_vm._s(_vm.__("Are you sure you want to cancel this meeting?", "wpcal"))+" ")]),_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.$options.filters.wpcal_unix_to_from_to_time_full_date_day_tz(
            _vm.booking_details.booking_from_time,
            _vm.booking_details.booking_to_time,
            _vm.tz,
            _vm.time_format
          ) + '.'
        )}}),_c('br'),(_vm.client_end == 'admin')?_c('div',[_vm._v(" with "+_vm._s(_vm.booking_details.invitee_name)+" ("+_vm._s(_vm.booking_details.invitee_email)+") ")]):_vm._e(),_c('div',{staticStyle:{"margin-top":"10px"}},[_vm._v(" "+_vm._s(_vm.__("Reason for cancellation:", "wpcal"))+" ")]),_c('div',{staticClass:"wpc-form-row"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.cancel_reason_final),expression:"cancel_reason_final"}],attrs:{"rows":"2"},domProps:{"value":(_vm.cancel_reason_final)},on:{"input":function($event){if($event.target.composing){ return; }_vm.cancel_reason_final=$event.target.value}}}),_vm._v(" "),(_vm.client_end == 'admin')?_c('div',{staticClass:"txt-help"},[_vm._v(" "+_vm._s(_vm.T__( "Cancellation reason will be communicated to invitee.", "wpcal" ))+" ")]):_vm._e()]),_c('div',{staticClass:"booking-cancel-action-btns"},[_c('button',{staticClass:"wpc-btn primary lg",staticStyle:{"margin":"10px 10px 10px 0","padding-left":"10px","padding-right":"10px"},attrs:{"type":"button","disabled":!_vm.cancel_booking_btn_is_enabled},on:{"click":function($event){return _vm.$emit('cancel-booking', _vm.booking_details)}}},[_vm._v(" "+_vm._s(_vm.__("Yes, cancel.", "wpcal"))+" ")]),_c('button',{staticClass:"wpc-btn primary lg",staticStyle:{"margin":"10px 10px 10px 0","padding-left":"10px","padding-right":"10px"},attrs:{"type":"button"},on:{"click":function($event){return _vm.$modal.hide('booking_cancel_confirm_modal')}}},[_vm._v(" "+_vm._s(_vm.__("No, don't.", "wpcal"))+" ")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }