<template>
  <span>
    <span
      v-if="
        type == 'display_booking_left_info_section' &&
          is_single_location_and_no_input_required
      "
    >
      <div
        class="event-type-location"
        :class="{
          ['location_' + single_location_label_before_booking.type]: true
        }"
      >
        {{ single_location_label_before_booking.label }}
      </div>
    </span>

    <!-- display_booking_location_options - STARTS HERE -->
    <span
      v-if="
        type == 'display_booking_location_options' &&
          is_multiple_locations_or_input_required
      "
    >
      <form-row :validator="$v.type">
        <label class="wpc-label"
          >{{ __("How shall we connect?", "wpcal")
          }}<em>{{ __("required", "wpcal") }}</em></label
        >
        <ul
          class="selector block location"
          :style="is_multiple_locations ? '' : ''"
        >
          <li v-for="(location, index) in locations" :key="index">
            <input
              type="radio"
              name="location_radio"
              :id="'location_' + index"
              v-model="selected_location_index"
              :value="index"
              v-if="is_multiple_locations"
              @focus="
                is_location_requires_input(location) &&
                  booking_location_option_focus_on_txt_box(
                    'location_txt_' + index
                  )
              "
              @click="
                is_location_requires_input(location) &&
                  booking_location_option_focus_on_txt_box(
                    'location_txt_' + index
                  )
              "
            />
            <label
              class="wpc-label"
              :class="{ ['location_' + location.type]: true }"
              :for="'location_' + index"
            >
              <!-- SVG References -->
              <svg
                class="icon-fill phone-reciever"
                v-if="location.type === 'phone'"
              >
                <use xlink:href="#icon-phone-reciever"></use>
              </svg>
              <svg
                class="icon-fill pin-solid"
                v-if="
                  location.type === 'physical' ||
                    location.type === 'ask_invitee'
                "
              >
                <use xlink:href="#icon-pin-solid"></use>
              </svg>
              {{ booking_location_option_label(location) }}
              <div>
                <!-- for using pre -->
                <!-- eslint-disable -->
                <div
                  v-if="
                    booking_location_option_help_txt(location) &&
                      (location.type == 'ask_invitee' ||
                        selected_location_index === index)
                  "
                  class="txt-help"
                  :class="{ pre: location.type == 'ask_invitee' }"
                >
                  {{ booking_location_option_help_txt(location) }}
                </div>
                <!-- eslint-enable -->
                <form-row
                  :validator="get_validator_for_location_while_booking(index)"
                  :style_form_row="
                    location.type != 'ask_invitee' ? 'margin-bottom:0;' : ''
                  "
                  v-if="
                    is_location_requires_input(location) &&
                      selected_location_index === index
                  "
                >
                  <input
                    :type="location.type == 'phone' ? 'tel' : 'text'"
                    v-model="location.form.location"
                    @change="$v.form.location.$touch()"
                    :ref="'location_txt_' + index"
                    :maxlength="get_maxlength_from_validation($v.form.location)"
                    :autocomplete="location.type == 'phone' ? 'tel' : ''"
                    :inputmode="location.type == 'phone' ? 'tel' : ''"
                  />
                  <CharLeft
                    v-if="location.type == 'ask_invitee'"
                    :maxlength="get_maxlength_from_validation($v.form.location)"
                    :input_text="location.form.location"
                  />
                  <div v-if="location.type == 'phone'" class="txt-help">
                    {{
                      __(
                        "Please enter phone number with country code. E.g. +1 555 555 1234",
                        "wpcal"
                      )
                    }}
                  </div>
                </form-row>
                <div
                  v-else-if="
                    location.type !== 'phone' &&
                      location.type !== 'ask_invitee' &&
                      location.form &&
                      location.form.location
                  "
                >
                  {{ location.form.location }}
                </div>
              </div>
            </label>
          </li>
        </ul>
      </form-row>
    </span>
    <!-- display_booking_location_options - ENDS HERE -->

    <!-- display_user_after_booking_location - STARTS HERE -->
    <span
      v-if="
        type == 'display_user_after_booking_location' &&
          !_isEmpty(location_details) &&
          location_details.type
      "
    >
      <div
        class="event-type-location"
        :class="{
          ['location_' + location_details.type]: true
        }"
        v-if="after_booking_location_details"
      >
        {{ after_booking_location_details.location }}
        <span v-if="after_booking_location_details.link">
          -
          <a :href="after_booking_location_details.link" target="_blank">{{
            after_booking_location_details.link
          }}</a></span
        >
        <div
          v-if="
            after_booking_location_details.display_meeting_id_str &&
              after_booking_location_details.display_meeting_id_str
          "
        >
          {{ after_booking_location_details.display_meeting_id_str }}
        </div>

        <!-- for using pre -->
        <!-- eslint-disable-next-line -->
        <div v-if="after_booking_location_details.location_extra" class="pre">
          {{ after_booking_location_details.location_extra }}
        </div>
        <div
          v-if="
            after_booking_location_details.password_data &&
              after_booking_location_details.password_data.password
          "
        >
          {{ after_booking_location_details.password_data.label }}:
          {{ after_booking_location_details.password_data.password }}
        </div>
      </div>
    </span>
    <!-- display_user_after_booking_location - ENDS HERE -->

    <!-- <div v-else-if=""></div> -->
  </span>
</template>

<script>
import CharLeft from "@/components/form_elements/CharLeft.vue";

export default {
  name: "LocationAdvanced",
  components: {
    CharLeft
  },
  model: {
    prop: "booking_selected_location",
    event: "booking-selected-location-value-changed"
  },
  props: {
    type: {
      type: String
    },
    locations: {
      type: Array
      ///default: () => []
    },
    location_details: {
      //type: Object //commented for console error empty object coming as empty array due to php - if condition later checked
    },
    booking_details: {
      type: Object
    },
    service_admin_details: {
      type: Object
    },
    booking_selected_location: {},
    $v: {}
  },
  data() {
    return {};
  },
  computed: {
    is_single_location() {
      if (Array.isArray(this.locations) && this.locations.length == 1) {
        return true;
      }
      return false;
    },
    is_single_location_and_no_input_required() {
      if (this.is_single_location) {
        if (!this.is_location_requires_input(this.locations[0])) {
          return true;
        }
      }
      return false;
    },
    is_multiple_locations_or_input_required() {
      if (this.is_multiple_locations) {
        return true;
      }
      if (
        this.locations.length === 1 &&
        this.is_location_requires_input(this.locations[0])
      ) {
        return true;
      }
      return false;
    },
    is_multiple_locations() {
      if (!Array.isArray(this.locations) || this.locations.length == 0) {
        return false;
      }
      if (this.locations.length > 1) {
        return true;
      }
      return false;
    },
    single_location_label_before_booking() {
      if (this.is_single_location_and_no_input_required) {
        if (
          this.locations[0].type == "physical" ||
          this.locations[0].type == "custom"
        ) {
          return {
            type: this.locations[0].type,
            label: this.locations[0].form.location,
            location_extra: this.locations[0].form.location_extra
              ? this.locations[0].form.location_extra
              : ""
          };
        }
        if (this.locations[0].type == "phone") {
          //who_calls invitee already checked
          return {
            type: this.locations[0].type,
            label: "Phone",
            location_extra: null
          };
        }
      }
      return {
        type: this.locations[0].type,
        label: this.__(
          "Web conference - The meeting link will be emailed upon confirmation.",
          "wpcal"
        ),
        location_extra: null
      };
    },
    selected_location_index: {
      get: function() {
        return this.find_index_of_selected_location(
          this.locations,
          this.booking_selected_location
        );
      },
      set: function(index) {
        let selected_location = {};
        if (typeof this.locations[index] !== "undefined") {
          selected_location = this.locations[index];
        }
        this.$emit(
          "booking-selected-location-value-changed",
          selected_location
        );
      }
    },
    after_booking_location_details() {
      return this.get_after_booking_location_details(this.location_details);
    }
  },
  methods: {
    is_location_requires_input(location) {
      if (location.type == "ask_invitee") {
        return true;
      } else if (
        location.type == "phone" &&
        location.form &&
        location.form.who_calls == "admin"
      ) {
        return true;
      }
      return false;
    },
    find_index_of_selected_location(locations, selected_location) {
      if (
        this._isEmpty(selected_location) ||
        !selected_location.hasOwnProperty("type") ||
        !selected_location.type
      ) {
        return "";
      }
      let selected_location_index = locations.findIndex(list_item => {
        if (selected_location.type === list_item.type) {
          if (
            selected_location.type === "physical" ||
            selected_location.type === "custom"
          ) {
            return selected_location.form.location == list_item.form.location;
          } else {
            return true;
          }
        }
        return false;
      });
      if (selected_location_index != -1) {
        return selected_location_index;
      }
      return "";
    },
    check_and_set_if_single_location() {
      if (this.is_single_location) {
        this.selected_location_index = 0;
      }
    },
    get_after_booking_location_details(location) {
      if (!location || !location.type) {
        return { location: "" };
      }
      if (location.type === "physical" || location.type === "custom") {
        let location_extra = "";
        if (location.form && location.form.location_extra) {
          location_extra = location.form.location_extra;
        }
        return {
          location: location.form.location,
          location_extra: location_extra
        };
      }
      if (location.type === "ask_invitee") {
        return {
          location: location.form.location
        };
      }
      if (location.type === "phone" && location.form) {
        let location_str;
        if (this.client_end === "admin") {
          let invitee_name = this.booking_details.invitee_name;
          if (location.form.who_calls == "invitee") {
            location_str = this.Tsprintf(
              /* translators: 1: invitee/admin name 2: phone number */
              this.__("%1$s will call you on %2$s", "wpcal"),
              invitee_name,
              location.form.location
            );
          } else if (location.form.who_calls == "admin") {
            location_str = this.Tsprintf(
              /* translators: 1: invitee/admin name 2: phone number */
              this.__("You will call %1$s on %2$s", "wpcal"),
              invitee_name,
              location.form.location
            );
          }
        } else if (this.client_end === "user") {
          let admin_name = this.service_admin_details.display_name;
          if (location.form.who_calls == "invitee") {
            location_str = this.Tsprintf(
              /* translators: 1: invitee/admin name 2: phone number */
              this.__("You will call %1$s on %2$s", "wpcal"),
              admin_name,
              location.form.location
            );
          } else if (location.form.who_calls == "admin") {
            location_str = this.Tsprintf(
              /* translators: 1: invitee/admin name 2: phone number */
              this.__("%1$s will call you on %2$s", "wpcal"),
              admin_name,
              location.form.location
            );
          }
        }

        return { location: location_str };
      }
      if (
        location.type === "googlemeet_meeting" ||
        location.type === "zoom_meeting" ||
        location.type === "gotomeeting_meeting"
      ) {
        if (this.client_end === "admin") {
          let link = "";

          let location_str = this.Tsprintf(
            /* translators: %s: meeting app name */
            this.__("%s Web conference", "wpcal"),
            this.$options.filters.location_name_by_type(location.type)
          );
          if (location.form && location.form.location) {
            link = location.form.location;
          }
          let password_data = {};
          if (location.form && location.form.password_data) {
            password_data = location.form.password_data;
          }
          let display_meeting_id_str = "";
          if (location.form && location.form.display_meeting_id) {
            let meeting_id_label = this.__("Meeting ID", "wpcal");
            if (location.type === "googlemeet_meeting") {
              meeting_id_label = this.__("Meeting code", "wpcal");
            }
            display_meeting_id_str =
              this.$options.filters.location_name_by_type(location.type) +
              " " +
              meeting_id_label +
              ": " +
              location.form.display_meeting_id;
          }
          return {
            location: location_str,
            password_data: password_data,
            link: link,
            display_meeting_id_str: display_meeting_id_str
          };
        } else if (this.client_end === "user") {
          return {
            location: this.Tsprintf(
              /* translators: %s: Location name */
              this.__(
                "%s - The web conferencing details will be emailed to you.",
                "wpcal"
              ),
              this.$options.filters.location_name_by_type(location.type)
            )
          };
        }
      }
    },
    get_validator_for_location_while_booking(index) {
      if (index === this.selected_location_index) {
        return this.$v.form.location;
      }
      return {};
    },
    booking_location_option_label(location) {
      if (location.type === "physical" || location.type === "custom") {
        return "";
      }
      if (location.type === "ask_invitee") {
        return this.__("Enter a location of your choice", "wpcal");
      }
      if (
        location.type === "phone" &&
        location.form &&
        location.form.who_calls === "invitee"
      ) {
        return this.__("You call me at the scheduled time.", "wpcal");
      }
      return this.$options.filters.location_name_by_type(location.type);
    },
    booking_location_option_help_txt(location) {
      if (location.type === "phone" && location.form) {
        if (location.form.who_calls === "admin") {
          return this.Tsprintf(
            /* translators: %s: admin name */
            this.__(
              "Enter your phone number. %s will call you here at the scheduled time.",
              "wpcal"
            ),
            this.service_admin_details.display_name
          );
        } else if (location.form.who_calls === "invitee") {
          return this.__(
            "The phone number will be provided upon confirmation.",
            "wpcal"
          );
        }
      }
      if (
        location.type === "googlemeet_meeting" ||
        location.type === "zoom_meeting" ||
        location.type === "gotomeeting_meeting"
      ) {
        return this.__(
          "Web conference - The meeting link will be emailed upon confirmation.",
          "wpcal"
        );
      }
      if (
        location.type == "ask_invitee" &&
        location.form &&
        location.form.location_extra
      ) {
        return location.form.location_extra;
      }
      return "";
    },
    booking_location_option_focus_on_txt_box(ref) {
      setTimeout(() => {
        this.$refs[ref][0].focus();
      }, 100);
    }
  },
  mounted() {
    if (this.type === "display_booking_location_options") {
      this.check_and_set_if_single_location();
    }
  }
};
</script>
