<template>
  <span class="mla" style="margin-right: 20px;"
    >{{ T__("Need help?") }}
    <a
      class="support-link"
      href="https://help.wpcal.io/?utm_source=wpcal_plugin&utm_medium=admin_header"
      target="_blank"
      >{{ T__("Help docs") }}</a
    >
    &bull;
    <a
      class="support-link"
      href="https://wpcal.io/support/?utm_source=wpcal_plugin&utm_medium=admin_header"
      target="_blank"
      >{{ T__("Contact Support") }}</a
    ></span
  >
</template>

<script>
export default {
  name: "AdminHeaderRight"
};
</script>
