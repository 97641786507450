<template>
  <div>
    <div
      style="text-align:center;"
      v-if="old_booking && old_booking.unique_link && client_end === 'user'"
    >
      {{ __("Reschedule booking", "wpcal") }}
    </div>
    <BookingStep1
      v-if="steps_flow.step1"
      :booking_type="booking_type"
      :service_details="service_details"
      :form="form"
      :availabile_slots_details="availabile_slots_details"
      :available_dates="available_dates"
      :availability_date_ranges="availability_date_ranges"
      :move_to_first_available_date="move_to_first_available_date"
      :service_admin_details="service_admin_details"
      :old_booking="old_booking"
      :current_user_details="current_user_details"
      @next-step="set_active_step_and_toggle($event)"
      @calendar-month-moved-to="process_calendar_month_move_event($event)"
    ></BookingStep1>
    <BookingStep2
      :$v="$v"
      v-if="client_end === 'admin' && steps_flow.step2"
      :booking_type="booking_type"
      :service_details="service_details"
      :form="form"
      :service_admin_details="service_admin_details"
      :old_booking="old_booking"
      :create_booking_btn_is_enabled="create_booking_btn_is_enabled"
      :service_locations="service_locations"
      @form-submit="add_booking"
      @reschedule-form-submit="reschedule_booking"
      @change-step="set_active_step_and_toggle($event)"
    ></BookingStep2>
    <router-view
      name="step2"
      :$v="$v"
      v-if="client_end === 'user' && steps_flow.step2"
      :booking_type="booking_type"
      :service_details="service_details"
      :form="form"
      :service_admin_details="service_admin_details"
      :old_booking="old_booking"
      :create_booking_btn_is_enabled="create_booking_btn_is_enabled"
      :service_locations="service_locations"
      @form-submit="add_booking"
      @reschedule-form-submit="reschedule_booking"
    ></router-view>
    <NewBookingsDisabled
      v-if="
        service_details.hasOwnProperty('status') && service_details.status != 1
      "
    />
    <v-dialog @before-closed="check_and_may_close_dialog" />
    <BookingBottomPoweredBy />
  </div>
</template>

<script>
import BookingStep1 from "@/components/user/BookingStep1.vue";
import BookingStep2 from "@/components/user/BookingStep2.vue";
import NewBookingsDisabled from "@/components/user/NewBookingsDisabled.vue";
import BookingBottomPoweredBy from "@/components/user/BookingBottomPoweredBy.vue";

import {
  required,
  requiredIf,
  email,
  maxLength
  // minLength,
  // integer
} from "vuelidate/lib/validators";
import {
  valid_phone,
  valid_phone_if,
  get_url_param_by_name,
  html_entities
} from "@/utils/common_func.js";

import { compareAsc } from "date-fns";

import { isEmpty as _isEmpty } from "lodash-es";

import axios from "axios";

export default {
  components: {
    BookingStep1,
    BookingStep2,
    NewBookingsDisabled,
    BookingBottomPoweredBy
  },
  props: {
    admin_end_booking_type: {
      type: String
    },
    admin_end_service_id: {
      type: String
    },
    admin_end_old_booking_unique_link: {
      type: String
    },
    admin_end_is_schedule_invitee_again: {
      type: Boolean
    }
  },
  data() {
    return {
      service_id: null,
      steps_flow: {
        step1: true,
        step2: false
      },
      form: {
        booking_date: "",
        booking_slot: null,
        invitee_name: "",
        invitee_email: "",
        invitee_tz: "",
        invitee_question_answers: [],
        reschedule_reason: "",
        location: {}
      },
      availabile_slots_details_db: {},
      availability_date_ranges: {},
      current_user_details: {},
      service_details: {},
      service_admin_details: {},
      move_to_first_available_date: false,
      can_load_slots_for_calendar_month_move_event: false,
      current_calendar_month: {},
      booking_type: "new",
      old_booking_unique_link: "",
      old_booking: {},
      is_schedule_invitee_again: false,
      create_booking_btn_is_enabled: true,
      service_locations: []
    };
  },
  validations() {
    let validations_rules = {
      form: {
        //booking_date: "",
        //booking_slot: null,
        invitee_name: { required },
        invitee_email: { required, email },
        invitee_question_answers: {
          $each: {
            answer: {
              required: requiredIf(function(value_model) {
                return value_model.is_required == 1;
              }),
              valid_phone_if: valid_phone_if
            }
          }
        },
        location: {
          type: {
            required: requiredIf(function() {
              return this.service_locations.length > 0;
            })
          },
          form: {
            location: {
              required: requiredIf(function() {
                if (
                  this.form.location &&
                  (this.form.location.type == "ask_invitee" ||
                    (this.form.location.type == "phone" &&
                      this.form.location.form.who_calls == "admin"))
                ) {
                  return true;
                }
              })
            }
          }
        }
      },
      step2: [
        "form.invitee_name",
        "form.invitee_email",
        "form.invitee_question_answers",
        "form.location.type",
        "form.location.form.location"
      ],
      reschedule_step2: [
        "form.invitee_question_answers",
        "form.location.type",
        "form.location.form.location"
      ]
    };

    if (
      this.form.location &&
      (this.form.location.type == "ask_invitee" ||
        (this.form.location.type == "phone" &&
          this.form.location.form.who_calls == "admin"))
    ) {
      validations_rules.form.location.form.location[
        "max_char_length_500"
      ] = maxLength(500);
    }
    if (
      this.form.location &&
      this.form.location.type == "phone" &&
      this.form.location.form.who_calls == "admin"
    ) {
      validations_rules.form.location.form.location[
        "valid_phone"
      ] = valid_phone;
    }
    return validations_rules;
  },
  computed: {
    availabile_slots_details() {
      //recreating from this.availabile_slots_details_db for new date which used in object key for timezone changes
      let __all_details = this.availabile_slots_details_db;
      if (!__all_details || typeof __all_details.slots != "object") {
        return __all_details;
      }
      let __tmp = this.clone_deep(__all_details);
      __tmp.slots = {};

      for (let old_date in __all_details.slots) {
        for (let some_key in __all_details.slots[old_date].slots) {
          let _slot = __all_details.slots[old_date].slots[some_key];

          let new_date = this.$options.filters.wpcal_unix_to_date(
            _slot.from_time,
            this.tz
          );
          if (!__tmp.slots.hasOwnProperty(new_date)) {
            __tmp.slots[new_date] = {};
          }
          if (!__tmp.slots[new_date].hasOwnProperty("slots")) {
            __tmp.slots[new_date]["slots"] = [];
          }
          __tmp.slots[new_date].slots.push(_slot);
        }
      }

      for (let new_date in __tmp.slots) {
        __tmp.slots[new_date]["is_available"] = "1"; //as timezone changes available per day data splits to two days so always 1 here
        __tmp.slots[new_date]["is_all_booked"] = "0";
        if (__tmp.slots[new_date].slots.length == 0) {
          __tmp.slots[new_date]["is_all_booked"] = "1";
        }
      }
      return __tmp;
    },
    available_dates() {
      let old_hidden_avaialble_date_to_disable_other_dates = [
        this.date_parse("1999-01-01")
      ];
      let available_dates = [];
      if (
        typeof this.availabile_slots_details !== "object" ||
        typeof this.availabile_slots_details.slots !== "object"
      ) {
        return old_hidden_avaialble_date_to_disable_other_dates;
      }

      // let current_view_year_month = "";
      // if (
      //   this.current_calendar_month &&
      //   this.current_calendar_month.hasOwnProperty("month")
      // ) {
      //   let zerofilled_month = ("00" + this.current_calendar_month.month).slice(
      //     -2
      //   );
      //   current_view_year_month =
      //     this.current_calendar_month.year + "-" + zerofilled_month;
      // }
      for (let date in this.availabile_slots_details.slots) {
        // if (current_view_year_month) {
        //   if (date.indexOf(current_view_year_month) === -1) {
        //     //to load only current view month slots after tz conversion
        //     continue;
        //   }
        // }
        if (
          this.availabile_slots_details.slots[date].is_available == "1" &&
          this.availabile_slots_details.slots[date].is_all_booked == "0"
        ) {
          available_dates.push(this.date_parse(date));
        }
      }
      return available_dates.length
        ? available_dates
        : old_hidden_avaialble_date_to_disable_other_dates;
    },
    ajax_main_action() {
      if (this.client_end === "admin") {
        return "wpcal_process_admin_ajax_request";
      }
      return "wpcal_process_user_ajax_request";
    }
  },
  methods: {
    set_active_step_and_toggle(step) {
      if (step == 1) {
        this.steps_flow.step1 = true;
        this.steps_flow.step2 = false;
      } else if (step == 2) {
        this.steps_flow.step1 = false;
        this.steps_flow.step2 = true;
        if (this.client_end === "user") {
          this.$router.push("/booking/confirm");
        }
      }
    },
    get_first_available_date() {
      if (this.available_dates.length == 0) {
        return "";
      }
      let available_dates = this.available_dates;
      available_dates.sort(compareAsc);
      if (
        available_dates[0].getTime() == this.date_parse("1999-01-01").getTime()
      ) {
        return "";
      }
      return available_dates[0];
    },
    process_calendar_month_move_event(to_month) {
      this.current_calendar_month = to_month;
      this.load_avaialble_slots_by_calendar_view(to_month);
    },
    pre_fill_form_from_url_params() {
      let invitee_name = get_url_param_by_name("wpcal_name");
      invitee_name = invitee_name ? html_entities(invitee_name).trim() : "";

      let invitee_email = get_url_param_by_name("wpcal_email");
      invitee_email = invitee_email ? html_entities(invitee_email).trim() : "";

      if (invitee_name) {
        this.form.invitee_name = invitee_name;
      }
      if (invitee_email) {
        this.form.invitee_email = invitee_email;
      }
    },
    load_page_details() {
      let wpcal_request = {};

      let action_service_details = "get_service_details_for_booking";
      wpcal_request[action_service_details] = {
        service_id: this.service_id
      };

      let action_user_details = "get_current_user_details_for_booking";
      wpcal_request[action_user_details] = {
        dummy__: ""
      };

      let action_availabile_slots = "get_initial_service_availabile_slots";
      wpcal_request[action_availabile_slots] = {
        service_id: this.service_id
      };

      if (this.booking_type == "reschedule") {
        wpcal_request[action_availabile_slots][
          "exclude_booking_unique_link"
        ] = this.old_booking_unique_link;
      }

      let action_old_booking_details;
      if (
        this.booking_type == "reschedule" ||
        (this.booking_type == "new" && this.is_schedule_invitee_again === true)
      ) {
        action_old_booking_details = "get_booking_by_unique_link";
        wpcal_request[action_old_booking_details] = {
          unique_link: this.old_booking_unique_link
        };
      }

      let post_data = {
        action: this.ajax_main_action,
        wpcal_request: wpcal_request
      };

      axios
        .post(window.wpcal_ajax.ajax_url, post_data)
        .then(response => {
          //console.log(response);
          if (response.data[action_service_details].status == "success") {
            this.service_details =
              response.data[action_service_details].service_data;
            this.service_admin_details =
              response.data[action_service_details].service_admin_data;
            this.service_locations = this.clone_deep(
              this.service_details.locations
            );
            if (this.service_details.status != 1) {
              this.steps_flow = {};
            }
          }
          if (response.data[action_user_details].status == "success") {
            this.current_user_details =
              response.data[action_user_details].user_data;
            if (
              this.current_user_details &&
              typeof this.current_user_details["is_pre_fill_user_details"] !==
                "undefined" &&
              this.current_user_details.is_pre_fill_user_details
            ) {
              if (typeof this.current_user_details["name"] !== "undefined") {
                this.form.invitee_name = this.current_user_details.name;
              }
              if (typeof this.current_user_details["email"] !== "undefined") {
                this.form.invitee_email = this.current_user_details.email;
              }
            }
            this.pre_fill_form_from_url_params();
          }
          if (
            this.booking_type == "reschedule" ||
            (this.booking_type == "new" &&
              this.is_schedule_invitee_again === true)
          ) {
            if (response.data[action_old_booking_details].status == "success") {
              let _old_booking =
                response.data[action_old_booking_details].booking_data;
              this.form.invitee_name = _old_booking.invitee_name;
              this.form.invitee_email = _old_booking.invitee_email;
              this.form.invitee_tz = _old_booking.invitee_tz;

              if (this.booking_type == "reschedule") {
                let allow_reschedule = this.check_old_booking_reschedulable(
                  _old_booking
                );
                if (allow_reschedule) {
                  this.old_booking = _old_booking;
                  this.form.invitee_question_answers =
                    _old_booking.invitee_question_answers;
                }
              }
            }
          }
          if (
            response.data[action_availabile_slots].status == "success" &&
            response.data[action_availabile_slots].availabile_slots_details &&
            !_isEmpty(
              response.data[action_availabile_slots].availabile_slots_details
                .slots
            )
          ) {
            this.availabile_slots_details_db =
              response.data[action_availabile_slots].availabile_slots_details;
            this.move_to_first_available_date = this.get_first_available_date();
            setTimeout(() => {
              this.can_load_slots_for_calendar_month_move_event = true; //hackish fix to avoid inital calendar view change event and one after initial load
            }, 100);
          }
          if (
            response.data[action_availabile_slots].status == "success" &&
            response.data[action_availabile_slots].availability_date_ranges
          ) {
            this.availability_date_ranges =
              response.data[action_availabile_slots].availability_date_ranges;
          }
          if (this.booking_type == "new") {
            this.add_questions_to_booking_form();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    load_avaialble_slots_by_calendar_view(to_month) {
      if (
        !this.can_load_slots_for_calendar_month_move_event ||
        !to_month ||
        typeof to_month !== "object" ||
        !to_month.hasOwnProperty("month")
      ) {
        return false;
      }

      this.move_to_first_available_date = false;
      let wpcal_request = {};

      let action_availabile_slots = "get_service_availabile_slots_by_month";
      wpcal_request[action_availabile_slots] = {
        service_id: this.service_id,
        current_month_view: to_month
      };

      if (this.booking_type == "reschedule") {
        wpcal_request[action_availabile_slots][
          "exclude_booking_unique_link"
        ] = this.old_booking_unique_link;
      }

      let post_data = {
        action: this.ajax_main_action,
        wpcal_request: wpcal_request
      };

      axios
        .post(window.wpcal_ajax.ajax_url, post_data)
        .then(response => {
          //console.log(response);
          if (
            response.data[action_availabile_slots].status == "success" &&
            response.data[action_availabile_slots]
              .month_availabile_slots_details &&
            !_isEmpty(
              response.data[action_availabile_slots]
                .month_availabile_slots_details.slots
            )
          ) {
            this.availabile_slots_details_db.slots =
              response.data[
                action_availabile_slots
              ].month_availabile_slots_details.slots;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    add_booking() {
      this.$v.step2.$touch();
      if (this.$v.step2.$anyError) {
        this.scroll_to_first_validation_error();
        return;
      }
      this.create_booking_btn_is_enabled = false;

      let final_form = this.clone_deep(this.form);
      final_form.service_id = this.service_id;
      //final_form.location = this.service_details.locations;
      if (this.client_end === "user") {
        final_form.invitee_tz = this.tz;
        final_form.booking_page_current_url = window.location.href;
        final_form.booking_page_post_id = window.wpcal_post_id;
      }

      let wpcal_request = {};
      let action_add_booking = "add_booking";
      wpcal_request[action_add_booking] = {
        form: final_form
      };

      let post_data = {
        action: this.ajax_main_action,
        wpcal_request: wpcal_request
      };

      axios
        .post(window.wpcal_ajax.ajax_url, post_data)
        .then(response => {
          //console.log(response);
          if (response.data[action_add_booking].status === "success") {
            let booking_unique_link =
              response.data[action_add_booking].booking_data.unique_link;

            setTimeout(() => {
              this.$store.dispatch(
                "run_booking_background_tasks_by_unique_link",
                { unique_link: booking_unique_link }
              );
            }, 500);

            let wpcal_booking_trigger = new CustomEvent("wpcal_booking_new", {
              detail: {
                booking_details: { unique_link: booking_unique_link }
              }
            });
            document.dispatchEvent(wpcal_booking_trigger);

            if (this.client_end === "user") {
              this.$router.push(
                "/booking/view/" + booking_unique_link + "/confirmed"
              );
              this.scroll_to("#wpcal-widget");
            } else if (this.client_end === "admin") {
              this.$emit("booking-added", {
                booking_unique_link: booking_unique_link
              });
            }
          } else {
            this.notify_single_action_result(
              response.data[action_add_booking],
              {
                success_msg: this.__("Booking saved.", "wpcal"),
                validation_errors: {
                  //to get feal of normal toast instead of inline
                  target: "",
                  zindex: "99999"
                }
              }
            );
          }
        })
        .catch(error => {
          console.log(error);
        })
        .then(() => {
          this.create_booking_btn_is_enabled = true;
        });
    },
    check_old_booking_reschedulable(old_booking) {
      if (old_booking && typeof old_booking === "object") {
        if (old_booking.hasOwnProperty("status") && old_booking.status != "1") {
          let dialog_text;
          if (old_booking.status == "-5") {
            dialog_text = this.__("Booking already rescheduled.", "wpcal");
          } else if (old_booking.status == "-1" || old_booking.status == "-2") {
            dialog_text = this.__("Booking already cancelled.", "wpcal");
          }

          this.on_close_go_to = "/booking/view/" + old_booking.unique_link; //unable to stop dialog close when clicking

          this.$modal.show("dialog", {
            title: this.__("Oops!", "wpcal"),
            text: dialog_text,
            //clickToClose: false, //clickToClose: false NOT WORKING
            buttons: [
              {
                title: this.__("Ok", "wpcal"),
                handler: () => {
                  this.$router.push("/booking/view/" + old_booking.unique_link);
                  this.can_close_dialog = true;
                  this.$modal.hide("dialog");
                },
                default: true
              }
            ]
          });
          return false;
        }
        let current_ts = Math.round(new Date().getTime() / 1000);
        if (
          old_booking.hasOwnProperty("booking_to_time") &&
          old_booking.booking_to_time < current_ts
        ) {
          this.on_close_go_to =
            "/booking/schedule_again/" + old_booking.unique_link; //unable to stop dialog close when clicking
          this.$modal.show("dialog", {
            title: this.__("Oops!", "wpcal"),
            text: this.__(
              "Booking slot was in the past and cannot be rescheduled. Do you want to make new booking?",
              "wpcal"
            ),
            //clickToClose: false, //clickToClose: false NOT WORKING
            buttons: [
              {
                title: this.__("Yes", "wpcal"),
                handler: () => {
                  this.$router.push(
                    "/booking/schedule_again/" + old_booking.unique_link
                  );
                  this.can_close_dialog = true;
                  this.$modal.hide("dialog");
                },
                default: true
              },
              {
                title: this.__("No", "wpcal"),
                handler: () => {
                  this.$router.push("/booking/view/" + old_booking.unique_link);
                  this.can_close_dialog = true;
                  this.$modal.hide("dialog");
                },
                default: true
              }
            ]
          });
          return false;
        }
      }
      return true;
    },
    //eslint-disable-next-line
    check_and_may_close_dialog(event) {
      if (typeof this.on_close_go_to !== "undefined" && this.on_close_go_to) {
        let on_close_go_to = this.on_close_go_to;
        this.on_close_go_to = undefined;
        this.$router.push(on_close_go_to);
        //event.stop(); - not working for dialog
      }
    },
    reschedule_booking() {
      this.$v.reschedule_step2.$touch();
      if (this.$v.reschedule_step2.$anyError) {
        this.scroll_to_first_validation_error();
        return;
      }
      this.create_booking_btn_is_enabled = false;

      let final_form = this.clone_deep(this.form);
      final_form.service_id = this.service_id;
      //final_form.location = this.service_details.locations;
      if (this.client_end === "user") {
        final_form.invitee_tz = this.tz;
        final_form.booking_page_current_url = window.location.href;
        final_form.booking_page_post_id = window.wpcal_post_id;
      }

      let wpcal_request = {};
      let action_reschedule_booking = "reschedule_booking";
      wpcal_request[action_reschedule_booking] = {
        old_booking_unique_link: this.old_booking_unique_link,
        form: final_form
      };

      let post_data = {
        action: this.ajax_main_action,
        wpcal_request: wpcal_request
      };

      axios
        .post(window.wpcal_ajax.ajax_url, post_data)
        .then(response => {
          //console.log(response);
          if (
            response.data[action_reschedule_booking].hasOwnProperty("status")
          ) {
            //alert(response.data[action_reschedule_booking].status);
            if (response.data[action_reschedule_booking].status === "success") {
              let new_booking =
                response.data[action_reschedule_booking].new_booking_data;
              let new_booking_id =
                response.data[action_reschedule_booking].new_booking_id;

              let wpcal_booking_trigger = new CustomEvent(
                "wpcal_booking_rescheduled",
                {
                  detail: {
                    booking_details: { unique_link: new_booking.unique_link }
                  }
                }
              );
              document.dispatchEvent(wpcal_booking_trigger);

              setTimeout(() => {
                this.$store.dispatch(
                  "run_booking_background_tasks_by_unique_link",
                  { unique_link: new_booking.unique_link }
                );
              }, 500);

              if (this.client_end === "user") {
                this.$router.push(
                  "/booking/view/" + new_booking.unique_link + "/rescheduled"
                );
                this.scroll_to("#wpcal-widget");
              } else if (this.client_end === "admin") {
                this.$emit("booking-rescheduled", {
                  old_booking: this.old_booking,
                  new_booking: new_booking,
                  new_booking_id: new_booking_id
                });
              }
            } else {
              this.notify_single_action_result(
                response.data[action_reschedule_booking],
                {
                  success_msg: this.__("Booking saved.", "wpcal"),
                  validation_errors: {
                    //to get feal of normal toast instead of inline
                    target: "",
                    zindex: "99999"
                  }
                }
              );
            }
          }
        })
        .catch(error => {
          console.log(error);
        })
        .then(() => {
          this.create_booking_btn_is_enabled = true;
        });
    },
    add_questions_to_booking_form() {
      if (
        !this.service_details.invitee_questions ||
        typeof this.service_details.invitee_questions !== "object" ||
        !this.service_details.invitee_questions.hasOwnProperty("questions") ||
        !Array.isArray(this.service_details.invitee_questions.questions)
      ) {
        return;
      }
      this.form.invitee_question_answers = JSON.parse(
        JSON.stringify(this.service_details.invitee_questions.questions)
      ); //JSON.parse(JSON.stringify()) for without reference

      for (let i in this.form.invitee_question_answers) {
        //this.form.invitee_question_answers[i]["answer"] = "";
        this.$set(this.form.invitee_question_answers[i], "answer", ""); //for reactivity
      }
    },
    load_page() {
      Object.assign(this.$data, this.$options.data.apply(this)); //re-initilize data in Vue
      this.service_id = window.wpcal_booking_service_id;
      if (this.client_end === "user") {
        if (this.$route.name == "booking_reschedule") {
          this.booking_type = "reschedule";
          this.old_booking_unique_link = this.$route.params.unique_link;
        } else if (this.$route.name == "booking") {
          this.booking_type = "new";
          this.old_booking_unique_link = "";
        } else if (this.$route.name == "booking_schedule_again") {
          this.booking_type = "new";
          this.old_booking_unique_link = this.$route.params.unique_link;
          this.is_schedule_invitee_again = true;
        }
      } else if (this.client_end === "admin") {
        this.service_id = this.admin_end_service_id;
        if (
          this.admin_end_booking_type == "reschedule" &&
          this.admin_end_old_booking_unique_link
        ) {
          this.booking_type = "reschedule";
          this.old_booking_unique_link = this.admin_end_old_booking_unique_link;
        } else if (this.admin_end_booking_type == "new") {
          this.booking_type = "new";
          this.old_booking_unique_link = "";
          if (this.admin_end_is_schedule_invitee_again === true) {
            this.old_booking_unique_link = this.admin_end_old_booking_unique_link;
            this.is_schedule_invitee_again = this.admin_end_is_schedule_invitee_again;
          }
        }
      }
      this.load_page_details();
    }
  },
  watch: {
    // //eslint-disable-next-line
    // "$route.name": function(new_route_name, old_route_name) {
    //   //console.log(new_route_name, old_route_name);
    //   if (this.client_end === "user") {
    //     if (this.$route.path != "/booking/confirm") {
    //       this.load_page();
    //     }
    //   }
    // },
    "$route.path": function() {
      if (this.client_end === "user") {
        if (
          this.$route.name == "booking" ||
          this.$route.name == "booking_reschedule" ||
          this.$route.name == "booking_schedule_again"
        ) {
          this.set_active_step_and_toggle(1);
        }
      }
    },
    "$route.params.unique_link": function(new_value) {
      if (
        this.$route.name == "booking_reschedule" ||
        this.$route.name == "booking_schedule_again"
      ) {
        if (this.old_booking_unique_link != new_value) {
          this.load_page();
        }
      }
    }
  },
  created() {
    if (this.client_end === "user") {
      this.service_id = window.wpcal_booking_service_id;
    }
  },
  mounted() {
    if (this.$route.path == "/booking/confirm") {
      if (
        !this.form.booking_slot ||
        typeof this.form.booking_slot !== "object"
      ) {
        this.$router.push("/booking");
        setTimeout(() => {
          this.scroll_to("#wpcal-widget");
        }, 200);
      }
    }
    this.load_page();
  }
};
</script>

<style src="@/assets/css/user_booking.css"></style>
