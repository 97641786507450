<template>
  <div style="font-size:15px;margin-top:20px;">
    <div>
      <div style="text-decoration:underline;">
        {{ __("Original Booking Info", "wpcal") }}
      </div>
      <div
        :class="{ striked: booking_step == 2 }"
        v-html="
          $options.filters.wpcal_unix_to_from_to_time_full_date_day_tz(
            old_booking.booking_from_time,
            old_booking.booking_to_time,
            tz,
            time_format
          ) + '.'
        "
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    old_booking: {
      type: Object
    },
    booking_step: {
      type: String
    }
  }
};
</script>

<style scoped>
.striked {
  text-decoration: line-through;
  opacity: 0.6;
}
</style>
