<template>
  <div>
    <div id="wpcal-widget">
      <div
        class="widget-main"
        :class="{
          'state-select-date': !(form.booking_date instanceof Date),
          'state-select-time': form.booking_date instanceof Date
        }"
      >
        <div class="modal_close_cont" v-if="client_end == 'admin'">
          <button @click="$modal.hide('service_booking_modal')"></button>
        </div>
        <BookingInfo
          :service_details="service_details"
          :service_admin_details="service_admin_details"
          :old_booking="old_booking"
          :booking_type="booking_type"
        />
        <div class="event-date-col" v-show="show_date_picker">
          <h4>{{ __("Select a Date", "wpcal") }}</h4>
          <v-date-picker
            is-inline
            :locale="this.$store.getters.get_site_locale_intl"
            style="width: 100%;"
            value="current_customizing_availability_date"
            v-model="form.booking_date"
            :available-dates="available_dates"
            :min-page="date_picker_min_page"
            :max-page="date_picker_max_page"
            ref="booking_date_picker"
            @update:to-page="$emit('calendar-month-moved-to', $event)"
          />

          <div
            class="visible-only-admins"
            v-if="
              current_user_details &&
                current_user_details.hasOwnProperty('is_wpcal_admin') &&
                current_user_details.is_wpcal_admin
            "
          >
            <div class="admin-only-content">
              Some slots missing?<br />
              <a
                href="https://help.wpcal.io/en/article/troubleshooting-why-slots-are-missingnot-available-1tbj1hg/"
                target="_blank"
                >See why</a
              >
              &nearr;
            </div>
            <h6>
              <svg
                class="eye"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  transform="matrix(4.166666666666667,0,0,4.166666666666667,0,0)"
                >
                  <path
                    d="M23.5,12S18.352,18.5,12,18.5.5,12,.5,12,5.648,5.5,12,5.5,23.5,12,23.5,12Z"
                    fill="none"
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M8.000 12.000 A4.000 4.000 0 1 0 16.000 12.000 A4.000 4.000 0 1 0 8.000 12.000 Z"
                    fill="none"
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M12,10a2,2,0,1,1-2,2"
                    fill="none"
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
              </svg>
              Visible only to WPCal admins
            </h6>
          </div>

          <TimeZoneSelector />
        </div>

        <SlotSelector
          :selected_date="form.booking_date"
          :availabile_slots_details="availabile_slots_details"
          :form="form"
          :show="show_slots_column"
          v-model="form.booking_slot"
          @slot-selector-back-clicked="slot_selector_back_clicked"
          @slot-selected="emit_next_step"
        />

        <div class="ribbon-mask">
          <a
            class="corner-brand"
            href="https://wpcal.io/?utm_source=wpcal_plugin&utm_medium=booking_corner_brand"
            target="_blank"
          >
            <div class="powered-by">{{ __("POWERED BY", "wpcal") }}</div>
            <div class="brand-name">WPCal.io</div>
          </a>
        </div>
        <div class="onboard-select-date"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SlotSelector from "@/components/form_elements/SlotSelector.vue";
import BookingInfo from "@/components/user/BookingInfo.vue";
import TimeZoneSelector from "@/components/form_elements/TimeZoneSelector.vue";

export default {
  components: {
    SlotSelector,
    BookingInfo,
    TimeZoneSelector
  },
  props: {
    booking_type: {
      type: String
    },
    service_details: {
      type: Object
    },
    form: {
      type: Object
    },
    availabile_slots_details: {
      type: Object
    },
    available_dates: {
      type: Array
    },
    availability_date_ranges: {
      type: Object
    },
    service_admin_details: {
      type: Object
    },
    move_to_first_available_date: {},
    old_booking: {
      type: Object
    },
    current_user_details: {
      type: Object
    }
  },
  data() {
    return {
      show_slots_column: true,
      update_to_page: "update:topage"
    };
  },
  computed: {
    show_date_picker() {
      if (
        this.$store.getters.get_app_cont_width < 700 &&
        this.form.booking_date instanceof Date
      ) {
        return false;
      }
      return true;
    },
    date_picker_min_page() {
      //to restrict selectable dates
      if (
        !this.availability_date_ranges ||
        !this.availability_date_ranges.current_available_from_date
      ) {
        return undefined;
      }
      let date_str = this.availability_date_ranges.current_available_from_date;
      let from_tz = this.service_details.timezone;
      let to_tz = this.tz;
      let month_year_obj = this.$options.filters.wpcal_convert_tz_std_date_to_month_year_obj(
        date_str,
        from_tz,
        to_tz
      );
      return month_year_obj;
    },
    date_picker_max_page() {
      //to restrict selectable dates
      if (
        !this.availability_date_ranges ||
        !this.availability_date_ranges.current_available_to_date
      ) {
        return undefined;
      }
      let date_str = this.availability_date_ranges.current_available_to_date;
      let from_tz = this.service_details.timezone;
      let to_tz = this.tz;
      let month_year_obj = this.$options.filters.wpcal_convert_tz_std_date_to_month_year_obj(
        date_str,
        from_tz,
        to_tz
      );
      return month_year_obj;
    }
  },
  methods: {
    emit_next_step() {
      this.$emit("next-step", 2);
    },
    slot_selector_back_clicked() {
      this.form.booking_date = "";
    }
  },
  watch: {
    tz() {
      //after changing timezone if selected booking_date is not in available_dates then empty the booking_date
      if (!(this.form.booking_date instanceof Date)) {
        return;
      }
      let result = this.available_dates.findIndex(date => {
        return date.getTime() == this.form.booking_date.getTime();
      });

      if (result === -1) {
        this.form.booking_date = "";
      }
    },
    move_to_first_available_date(new_value) {
      if (new_value) {
        const calendar = this.$refs.booking_date_picker;
        calendar.$refs.calendar.move(new_value);
      }
    }
  }
};
</script>

<style scoped>
.visible-only-admins {
  background-color: rgba(var(--accentClrRGB), 0.1);
  border: 1px dashed rgba(var(--accentClrRGB), 0.5);
  font-size: 14px;
  border-radius: 5px;
  margin: 0 10px;
  color: inherit;
  display: flex;
  line-height: 1.4em;
}
.visible-only-admins .admin-only-content {
  padding: 5px 10px;
}
.visible-only-admins .admin-only-content a {
  text-decoration: underline;
  text-underline-offset: unset;
  font-size: 13px;
}
.visible-only-admins h6 {
  font-size: 11px;
  margin: 5px;
  padding: 5px 10px;
  border-radius: 0 0 5px 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  background: #fff;
  border: 1px dashed rgba(var(--accentClrRGB), 0.5);
  width: 120px;
  margin-left: auto;
}
.visible-only-admins h6 svg.eye {
  stroke: inherit;
  width: 28px;
}
</style>
