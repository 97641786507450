<template>
  <span>
    <div
      v-if="validator.hasOwnProperty('$dirty')"
      class="wpc-form-row"
      :class="{ 'has-error': validator.$dirty && validator.$error }"
      :style="style_form_row"
    >
      <slot />
      <div class="wpc-form-error-msg" v-if="hasErrors">
        <div v-for="(error, index) in activeErrorMessages" :key="index">
          {{ error }}
        </div>
      </div>
      <slot name="after" />
    </div>
    <div v-else class="wpc-form-row" :style="style_form_row">
      <slot />
      <slot name="after" />
    </div>
  </span>
</template>

<script>
import { singleErrorExtractorMixin } from "vuelidate-error-extractor";

export default {
  mixins: [singleErrorExtractorMixin],
  props: {
    style_form_row: {
      type: String,
      default: ""
    }
  }
};
</script>
