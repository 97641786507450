<template>
  <div>
    <div id="wpcal-widget">
      <div class="widget-main state-form cf">
        <div class="modal_close_cont" v-if="client_end == 'admin'">
          <button @click="$modal.hide('service_booking_modal')"></button>
        </div>
        <BookingInfo
          :service_details="service_details"
          :service_admin_details="service_admin_details"
          :old_booking="old_booking"
          :booking_type="booking_type"
          booking_step="2"
        />
        <div class="event-form">
          <div class="icon-back" @click="go_back">
            <span>{{ __("Back", "wpcal") }}</span>
          </div>
          <div
            class="confirmed-timing"
            v-html="
              $options.filters.wpcal_unix_to_from_to_time_full_date_day_tz(
                form.booking_slot.from_time,
                form.booking_slot.to_time,
                tz,
                time_format
              ) + '.'
            "
          ></div>
          <div class="txt-h4" v-if="booking_type === 'new'">
            {{ __("Please enter your details", "wpcal") }}
          </div>
          <div class="wpc-form">
            <div v-if="booking_type === 'new'">
              <form-row :validator="$v.form.invitee_name">
                <label class="wpc-label"
                  >{{
                    __(
                      "Full Name",
                      "wpcal"
                    ) /* intentional line break, i18n search replace */
                  }}
                  <em>{{ __("required", "wpcal") }}</em></label
                >
                <input
                  type="text"
                  name="name"
                  autocomplete="name"
                  v-model="form.invitee_name"
                  @blur="$v.form.invitee_name.$touch()"
                  ref="invitee_name"
                />
              </form-row>
              <form-row :validator="$v.form.invitee_email">
                <label class="wpc-label"
                  >{{
                    __(
                      "Email Address",
                      "wpcal"
                    ) /* intentional line break, i18n search replace */
                  }}
                  <em>{{ __("required", "wpcal") }}</em></label
                >
                <input
                  type="email"
                  name="email"
                  autocomplete="email"
                  autocapitalize="off"
                  autocorrect="off"
                  v-model="form.invitee_email"
                  @blur="$v.form.invitee_email.$touch()"
                />
              </form-row>
            </div>
            <div v-if="booking_type === 'reschedule'">
              <form-row :validator="$v.form.reschedule_reason">
                <label class="wpc-label">{{
                  __("Reason for reschedule", "wpcal")
                }}</label>
                <textarea
                  v-model="form.reschedule_reason"
                  ref="reschedule_reason"
                />
                <div v-if="client_end == 'admin'" class="txt-help">
                  {{
                    T__(
                      "Reschedule reason will be communicated to invitee.",
                      "wpcal"
                    )
                  }}
                </div>
              </form-row>
            </div>

            <LocationAdvanced
              type="display_booking_location_options"
              :locations="service_locations"
              :service_admin_details="service_admin_details"
              :$v="$v.form.location"
              v-model="form.location"
            />

            <!-- custom questions -->
            <!-- <pre>{{ $v.form.invitee_question_answers.$each }}</pre> -->
            <form-row
              v-for="(question, index) in questions"
              :key="index"
              :validator="
                $v.form.invitee_question_answers.$each.$iter[index].answer
              "
            >
              <label class="wpc-label">
                <!-- for using pre -->
                <!-- eslint-disable-next-line -->
                <span class="pre">{{ question.question }}</span>
                <em
                  >{{
                    question.is_required == 1
                      ? __("required", "wpcal")
                      : __("optional", "wpcal")
                  }}
                </em></label
              >
              <textarea
                rows="3"
                v-model="question.answer"
                v-if="question.answer_type == 'textarea'"
                @blur="
                  $v.form.invitee_question_answers.$each.$iter[
                    index
                  ].answer.$touch()
                "
              />
              <input
                :type="question.answer_type == 'input_phone' ? 'tel' : 'text'"
                v-model="question.answer"
                v-if="
                  question.answer_type == 'input_text' ||
                    question.answer_type == 'input_phone'
                "
                @blur="
                  $v.form.invitee_question_answers.$each.$iter[
                    index
                  ].answer.$touch()
                "
                :autocomplete="
                  question.answer_type == 'input_phone' ? 'tel' : ''
                "
                :inputmode="question.answer_type == 'input_phone' ? 'tel' : ''"
              />
            </form-row>
            <!-- custom questions -->

            <div class="wpc-form-row mb0">
              <button
                v-if="booking_type === 'new'"
                type="button"
                class="wpc-btn primary lg mt20"
                :disabled="!create_booking_btn_is_enabled"
                @click="$emit('form-submit')"
              >
                {{ __("Schedule my Event", "wpcal") }}
              </button>
              <button
                v-if="booking_type === 'reschedule'"
                type="button"
                class="wpc-btn primary lg"
                :disabled="!create_booking_btn_is_enabled"
                @click="$emit('reschedule-form-submit')"
              >
                {{ __("Reschedule my Event", "wpcal") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BookingInfo from "@/components/user/BookingInfo.vue";
import LocationAdvanced from "@/components/LocationAdvanced.vue";

export default {
  components: {
    BookingInfo,
    LocationAdvanced
  },
  props: {
    booking_type: {
      type: String
    },
    service_details: {
      type: Object
    },
    service_admin_details: {
      type: Object
    },
    form: {
      type: Object
    },
    old_booking: {
      type: Object
    },
    create_booking_btn_is_enabled: {},
    service_locations: {},
    $v: {}
  },
  computed: {
    questions() {
      if (
        !this.form.invitee_question_answers ||
        !Array.isArray(this.form.invitee_question_answers)
      ) {
        return [];
      }
      return this.form.invitee_question_answers;
    }
  },
  methods: {
    go_back() {
      if (this.client_end == "user") {
        this.$router.go(-1);
        return;
      }
      if (this.client_end == "admin") {
        this.$emit("change-step", 1);
      }
    }
  },
  mounted() {
    if (this.$refs.invitee_name) {
      this.$refs.invitee_name.focus();
    } else if (this.$refs.reschedule_reason) {
      this.$refs.reschedule_reason.focus();
    }
  }
};
</script>
