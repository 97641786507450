import axios from "axios";
import { debounce } from "lodash-es";

axios.defaults.withCredentials = true; //to allow send and receive cookie

const common_module = {
  state: {
    client: {
      tz: null, //client is browser for tz
      mobile: window.innerWidth <= 699,
      initial_document_title: "",
      app_cont_width: "" //#wpcal_user_app or #wpcal_admin_app
    },
    store: {
      script_version: "0.9.4.4",
      is_debug: false,
      tz: null,
      site_tz: null,
      site_locale: "en_US",
      site_locale_intl: "en-US",
      general_settings: {},
      ajax_main_action: "",
      tp_locations_refresh_interval_instance: null,
      dynamic_cache: {}
    }
  },
  mutations: {
    set_client_tz: (state, value) => {
      state.client.tz = value;
    },
    set_client_mobile: (state, value) => {
      state.client.mobile = value;
    },
    set_client_initial_document_title: (state, value) => {
      state.client.initial_document_title = value;
    },
    set_is_debug: (state, value) => {
      state.store.is_debug = value;
    },
    set_tz: (state, value) => {
      state.store.tz = value;
    },
    set_app_cont_width: (state, value) => {
      state.client.app_cont_width = value;
    },
    set_ajax_main_action: (state, value) => {
      state.store.ajax_main_action = value;
    },
    set_general_settings: (state, value) => {
      state.store.general_settings = value;
    },
    set_tp_locations_refresh_interval_instance: (state, value) => {
      state.store.tp_locations_refresh_interval_instance = value;
    },
    set_store_by_obj: (state, value_obj) => {
      for (let prop in value_obj) {
        if (state.store.hasOwnProperty(prop)) {
          state.store[prop] = value_obj[prop];
        }
      }
    },
    set_dynamic_cache_by_prop: (state, prop_details) => {
      state.store.dynamic_cache[prop_details.prop] = {
        value: prop_details.value,
        expiry_secs: prop_details.expiry_secs,
        cached_ts: Math.floor(Date.now() / 1000)
      };
    },
    delete_dynamic_cache_by_prop(state, prop) {
      if (state.store.dynamic_cache.hasOwnProperty(prop)) {
        delete state.store.dynamic_cache[prop];
      }
    }
  },
  getters: {
    // get_client: state => state.client,
    // get_store: state => state.store,
    get_client_end: (state, getters, rootState) => rootState.client_end,
    get_client_tz: state => state.client.tz,
    get_client_initial_document_title: state =>
      state.client.initial_document_title,
    get_tz: state => {
      if (state.store.tz) {
        return state.store.tz;
      }
      return state.client.tz;
    },
    get_app_cont_width: state => state.client.app_cont_width,
    is_debug: state => state.store.is_debug,
    get_script_version: state => state.store.script_version,
    get_site_tz: state => state.store.site_tz,
    get_site_locale: state => state.store.site_locale,
    get_site_locale_intl: state => state.store.site_locale_intl,
    get_ajax_main_action: state => state.store.ajax_main_action,
    get_general_settings: state => state.store.general_settings,
    get_tp_locations_refresh_interval_instance: state =>
      state.store.tp_locations_refresh_interval_instance,
    get_time_display_format: state => {
      let time_display_format = "HH:mm";
      if (state.store.general_settings.time_format == "12hrs") {
        time_display_format = "hh:mm aaaaa'm'";
      }
      return time_display_format;
    },
    get_client_mobile: state => state.client.mobile,
    get_dynamic_cache: state => state.store.dynamic_cache
  },
  actions: {
    init(context) {
      context.dispatch("set_current_client_tz");
      context.dispatch("set_ajax_main_action");
      context.dispatch("get_settings_and_defaults");
      context.dispatch("manage_background_task");
      context.dispatch("listen_window_resize");
      context.dispatch("get_and_set_initial_document_title");
      context.dispatch("may_debug_listen_and_log_booking_events");

      setInterval(
        () => context.dispatch("clear_expired_props_in_dynamic_cache"),
        5000
      );

      setTimeout(
        () => context.dispatch("check_asset_preloading_will_benefit"),
        15000
      );
    },
    set_ajax_main_action(context) {
      let ajax_main_action =
        "wpcal_process_" + context.rootState.client_end + "_ajax_request";
      context.commit("set_ajax_main_action", ajax_main_action);
    },
    set_current_client_tz(context) {
      let current_client_tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      context.commit("set_client_tz", current_client_tz);
    },
    set_tz(context, value) {
      if (context.state.client.tz == value) {
        context.commit("set_tz", null);
      } else {
        context.commit("set_tz", value);
      }
      return context.dispatch("save_tz_in_db_or_cookie");
    },
    save_tz_in_db_or_cookie(context) {
      let user_tz = context.state.store.tz;

      let wpcal_request = {};
      let action = "save_user_tz";
      wpcal_request[action] = {
        tz: user_tz
      };

      let post_data = {
        action: context.getters.get_ajax_main_action,
        wpcal_request: wpcal_request
      };

      axios
        .post(window.wpcal_ajax.ajax_url, post_data)
        .then(response => {
          //console.log(response);
          if (response.data[action].status != "success") {
            console.log("Failed to save tz");
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    get_tz_from_db_or_cookie(context) {
      let wpcal_request = {};
      let action = "get_user_tz";
      wpcal_request[action] = {
        dummy__: ""
      };

      let post_data = {
        action: context.getters.get_ajax_main_action,
        wpcal_request: wpcal_request
      };

      axios
        .post(window.wpcal_ajax.ajax_url, post_data)
        .then(response => {
          //console.log(response);
          if (
            response.data[action].status == "success" &&
            response.data[action].tz
          ) {
            context.commit("set_tz", response.data[action].tz);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    listen_window_resize(context) {
      //initial value
      let app_cont_width = document.getElementById(
        "wpcal_" + context.rootState.client_end + "_app"
      )?.clientWidth;
      context.commit("set_app_cont_width", app_cont_width);

      window.addEventListener(
        "resize",
        debounce(() => {
          let mobile = window.innerWidth <= 699;
          context.commit("set_client_mobile", mobile);

          //following for unsupported ResizeObserver browsers
          // console.log("NON ResizeObserver");
          let app_cont_width = document.getElementById(
            "wpcal_" + context.rootState.client_end + "_app"
          )?.clientWidth;
          context.commit("set_app_cont_width", app_cont_width);
        }, 100)
      );

      try {
        // ResizeObserver will not be supporting in iOS < 13.4, other mobile browsers and old browsers, above resize event works well.
        const ro = new ResizeObserver(
          debounce(entries => {
            //console.log("ResizeObserver");

            let app_cont_width = document.getElementById(
              "wpcal_" + context.rootState.client_end + "_app"
            )?.clientWidth;
            context.commit("set_app_cont_width", app_cont_width);
          }, 100)
        );

        setTimeout(() => {
          //setTimeout required because the initial div replaced by vue
          let mount_element = document.getElementById(
            "wpcal_" + context.rootState.client_end + "_app"
          );
          if (!mount_element) {
            return;
          }
          ro.observe(mount_element);
        }, 100);
      } catch (err) {}
    },
    async get_settings_and_defaults(context, options) {
      let wpcal_request = {};
      let action_user_tz = "get_user_tz";
      wpcal_request[action_user_tz] = {
        dummy__: ""
      };

      let action_is_debug = "get_is_debug";
      wpcal_request[action_is_debug] = "dummy__";

      let action_general_settings = "get_general_settings_by_options";
      if (context.rootState.client_end === "admin") {
        action_general_settings = "get_general_settings";
      }
      wpcal_request[action_general_settings] = {
        options: ["time_format", "branding_color", "branding_font"]
      };

      let action_license_status = "license_status";
      if (context.rootState.client_end === "admin") {
        wpcal_request[action_license_status] = "dummy__";
      }

      let action_initial_common_data = "initial_common_data";
      wpcal_request[action_initial_common_data] = "dummy__";

      let action_intial_client_end_data =
        "initial_" + context.rootState.client_end + "_data";
      wpcal_request[action_intial_client_end_data] = "dummy__";

      let post_data = {
        action: context.getters.get_ajax_main_action,
        wpcal_request: wpcal_request
      };

      let request_options = {};
      if (options && options.hasOwnProperty("background_call")) {
        request_options = {
          params: { _remove_options_before_call: {} }
        };

        request_options.params._remove_options_before_call.background_call =
          options.background_call;
      }

      await axios
        .post(window.wpcal_ajax.ajax_url, post_data, request_options)
        .then(response => {
          //console.log(response);
          if (
            context.rootState.client_end === "admin" &&
            response.data?.request_result == "access_denied"
          ) {
            context.commit(
              "set_store_by_obj",
              { is_admin_end_access_granted: false },
              {
                root: true
              }
            );
            return;
          }
          if (
            response.data[action_user_tz].status === "success" &&
            response.data[action_user_tz].tz
          ) {
            context.commit("set_tz", response.data[action_user_tz].tz);
          }
          if (
            response.data[action_is_debug].status === "success" &&
            response.data[action_is_debug].is_debug
          ) {
            context.commit(
              "set_is_debug",
              response.data[action_is_debug].is_debug
            );
          }
          if (
            response.data[action_general_settings].status === "success" &&
            response.data[action_general_settings].general_settings
          ) {
            context.commit(
              "set_general_settings",
              response.data[action_general_settings].general_settings
            );
          }
          if (context.rootState.client_end === "admin") {
            if (
              response.data[action_license_status].status === "success" &&
              response.data[action_license_status].hasOwnProperty(
                "license_info"
              )
            ) {
              context.commit(
                "set_license_info",
                response.data[action_license_status].license_info,
                { root: true }
              );
            }
          }
          if (
            response.data[action_initial_common_data].status === "success" &&
            response.data[action_initial_common_data].hasOwnProperty("data")
          ) {
            context.commit(
              "set_store_by_obj",
              response.data[action_initial_common_data].data
            );
          }
          if (
            response.data[action_intial_client_end_data].status === "success" &&
            response.data[action_intial_client_end_data].hasOwnProperty("data")
          ) {
            context.commit(
              "set_store_by_obj",
              response.data[action_intial_client_end_data].data,
              { root: true }
            );
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    manage_background_task(context, options = {}) {
      let initial_time_out = 5000;
      if (!options || typeof options != "object") {
        options = {};
      }
      if (options.hasOwnProperty("initial_time_out")) {
        initial_time_out = options.initial_time_out;
      }
      clearTimeout(window.wpcal_run_background_timeout);
      window.wpcal_run_background_timeout = setTimeout(
        () => context.dispatch("run_background_task"),
        initial_time_out
      );
    },
    run_background_task(context) {
      let wpcal_request = {};
      let action_run_background_task = "run_background_task";
      wpcal_request[action_run_background_task] = {
        dummy__: ""
      };

      let post_data = {
        action: context.getters.get_ajax_main_action,
        wpcal_request: wpcal_request
      };

      let nextCallAfterNms = 2 * 60 * 1000; //2 mins in milli-seconds

      axios
        .post(window.wpcal_ajax.ajax_url, post_data, {
          params: { _remove_options_before_call: { background_call: true } }
        })
        .then(response => {
          //console.log(response);
          if (
            response.data.hasOwnProperty("is_task_waiting_now") &&
            response.data.is_task_waiting_now
          ) {
            nextCallAfterNms = 15 * 1000; // 15 secs in milli-seconds
          }
        })
        .catch(error => {
          console.log(error);
        })
        .then(function() {
          // always executed
          window.wpcal_run_background_timeout = setTimeout(
            () => context.dispatch("run_background_task"),
            nextCallAfterNms
          );
        });
    },
    run_booking_background_tasks_by_unique_link(context, booking_details) {
      let wpcal_request = {};
      let action_run_booking_background_tasks =
        "run_booking_background_tasks_by_unique_link";
      wpcal_request[action_run_booking_background_tasks] = booking_details;

      let post_data = {
        action: context.getters.get_ajax_main_action,
        wpcal_request: wpcal_request
      };

      axios
        .post(window.wpcal_ajax.ajax_url, post_data, {
          params: { _remove_options_before_call: { background_call: true } }
        })
        .then(response => {
          //console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    },
    get_and_set_initial_document_title(context) {
      let client_initial_document_title = document.title;
      context.commit(
        "set_client_initial_document_title",
        client_initial_document_title
      );
    },
    set_document_title(context, title) {
      let initial_title = context.getters.get_client_initial_document_title;
      if (!title) {
        document.title = initial_title;
        return;
      }
      let final_title = title + " ‹ " + initial_title;
      document.title = final_title;
    },
    check_asset_preloading_will_benefit(context) {
      //currently in end user section asset will be loaded if there is booking widget, this should be called only during that time
      if (context.rootState.client_end != "user") {
        return;
      }

      let match_count = 0;
      let ver_str = "?ver=" + context.getters.get_script_version;
      const urls = [
        "css/chunk-vendors.css" + ver_str,
        "css/chunk-common.css" + ver_str,
        "css/user.css" + ver_str,
        "js/chunk-vendors.min.js" + ver_str,
        "js/chunk-common.min.js" + ver_str,
        "js/user.min.js" + ver_str
      ];

      let scripts = document.getElementsByTagName("script");
      for (let script of scripts) {
        if (script?.src && script.src.indexOf("/wpcal/dist/") > -1) {
          let url_split_parts = script.src.split("/wpcal/dist/", 2);
          if (urls.indexOf(url_split_parts[1]) > -1) {
            match_count++;
          }
        }
      }

      let links = document.getElementsByTagName("link");
      for (let link of links) {
        if (
          link?.rel &&
          link.rel == "stylesheet" &&
          link?.href &&
          link.href.indexOf("/wpcal/dist/") > -1
        ) {
          let url_split_parts = link.href.split("/wpcal/dist/", 2);
          if (urls.indexOf(url_split_parts[1]) > -1) {
            match_count++;
          }
        }
      }

      let score = match_count / urls.length;

      let enable_preload = 0;
      if (score > 0.7) {
        enable_preload = 1;
      }

      let wpcal_request = {};
      let action_update_enable_preloading = "update_enable_preloading";
      wpcal_request[action_update_enable_preloading] = enable_preload;

      let post_data = {
        action: context.getters.get_ajax_main_action,
        wpcal_request: wpcal_request
      };

      axios
        .post(window.wpcal_ajax.ajax_url, post_data, {
          params: { _remove_options_before_call: { background_call: true } }
        })
        .then(response => {
          //console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    },
    get_dynamic_cache_by_prop(context, prop) {
      let dynamic_cache = context.getters.get_dynamic_cache;
      if (!dynamic_cache.hasOwnProperty(prop)) {
        return "";
      }
      let time_now_ts = Math.floor(Date.now() / 1000);
      if (
        dynamic_cache[prop].expiry_secs &&
        time_now_ts - dynamic_cache[prop].cached_ts >
          dynamic_cache[prop].expiry_secs
      ) {
        context.commit("delete_dynamic_cache_by_prop", prop);
        return "";
      }
      return dynamic_cache[prop].value;
    },
    clear_expired_props_in_dynamic_cache(context) {
      let dynamic_cache = context.getters.get_dynamic_cache;
      let time_now_ts = Math.floor(Date.now() / 1000);
      for (let prop in dynamic_cache) {
        if (
          dynamic_cache[prop].expiry_secs &&
          time_now_ts - dynamic_cache[prop].cached_ts >
            dynamic_cache[prop].expiry_secs
        ) {
          context.commit("delete_dynamic_cache_by_prop", prop);
        }
      }
    },
    may_debug_listen_and_log_booking_events() {
      if (!window?.wpcal_ajax?.is_debug) {
        return;
      }
      let booking_event_handler = function(e) {
        console.log(e);
      };

      document.addEventListener("wpcal_booking_new", booking_event_handler);
      document.addEventListener(
        "wpcal_booking_rescheduled",
        booking_event_handler
      );
      document.addEventListener(
        "wpcal_booking_cancelled",
        booking_event_handler
      );
    }
  }
};

export default common_module;
