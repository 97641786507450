export {
  __,
  _x,
  _n,
  _nx,
  __ as T__,
  _x as T_x,
  _n as T_n,
  _nx as T_nx,
  Tsprintf,
  _lang,
  debug_log,
  must_be_1_or_0,
  in_array,
  subset,
  get_url_param_by_name,
  html_entities,
  valid_phone,
  valid_phone_if,
  valid_date,
  min_date_as,
  valid_time,
  min_time_as,
  check_periods_collide,
  valid_color,
  hex_to_rgb_txt,
  date_parse,
  timezone_list,
  get_tz_name,
  weekdays_list,
  month_list,
  location_prop_by_type
};

import { helpers } from "vuelidate/lib/validators";
import { parseISO } from "date-fns";
import "url-polyfill";

// i18n - related fuctions starts here
const { __: wp__, _x: wp_x, _n: wp_n, _nx: wp_nx, sprintf } = wp.i18n;

const __ = (str, domain = "default") => {
  if (window.__wpcal_client_end != "user") {
    return str;
  }
  return wp__(str, domain);
};

const _x = (str, context, domain = "default") => {
  if (window.__wpcal_client_end != "user") {
    return str;
  }
  return wp_x(str, context, domain);
};

const _n = (singular_str, plural_str, num, domain = "default") => {
  if (window.__wpcal_client_end != "user") {
    return parseInt(num) == 1 ? singular_str : plural_str;
  }
  return wp_n(singular_str, plural_str, parseInt(num), domain);
};

const _nx = (singular_str, plural_str, num, context, domain = "default") => {
  if (window.__wpcal_client_end != "user") {
    return parseInt(num) == 1 ? singular_str : plural_str;
  }
  return wp_nx(singular_str, plural_str, parseInt(num), context, domain);
};

const Tsprintf = (format, ...args) => sprintf(format, ...args);
// i18n - related fuctions ends here

//to avoid name changed by webpack for pot file scanning "_lang.__()"" even if "_lang" changes "__()"" will not change
const _lang = { __, _x, _n, _nx, T__: __, T_x: _x, T_n: _n, T_nx: _nx };

const must_be_1_or_0 = value =>
  !helpers.req(value) ||
  value === "1" ||
  value === "0" ||
  value === 1 ||
  value === 0;

const in_array = param_array => value => {
  //console.log(value);
  return !helpers.req(value) || param_array.indexOf(value) !== -1;
};

const subset = param_array => value_array => {
  //console.log(param_array, value_array);
  return (
    !helpers.req(value_array) ||
    value_array.every(val => {
      //console.log(val);
      return (
        param_array.findIndex(param_val => {
          //console.log(param_val, val, param_val == val);
          return param_val == val;
        }) !== -1
      );
    })
  );
};

const get_url_param_by_name = name => {
  let url_params = window.location.search;
  let search = new URLSearchParams(url_params);
  let result = search.get(name);
  return result;
};

const html_entities = input_str => {
  return input_str
    .replace(/\&/g, "&amp;")
    .replace(/\</g, "&lt;")
    .replace(/\>/g, "&gt;")
    .replace(/\"/g, "&quot;")
    .replace(/\'/g, "&#x27;")
    .replace(/\//g, "&#x2F;");
};

const valid_phone = (value, v2, v3) => {
  const _value = value.replace(/extension|ext/gi, "");
  return (
    !helpers.req(value) ||
    /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/.test(_value)
  ); //regex - https://stackoverflow.com/a/53297852/188371
};

const valid_phone_if = (value, parent) => {
  if (typeof parent !== "object" || parent?.answer_type !== "input_phone") {
    return true;
  }
  const _value = value.replace(/extension|ext/gi, "");
  return (
    !helpers.req(value) ||
    /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/.test(_value)
  ); //regex - https://stackoverflow.com/a/53297852/188371
};

const valid_date = value =>
  !helpers.req(value) ||
  /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/.test(value);

const min_date_as = param => (value, model) => {
  return (
    !helpers.req(value) ||
    (model.hasOwnProperty(param) &&
      date_parse(model[param]).getTime() <= date_parse(value).getTime())
  );
};

const valid_time = value =>
  !helpers.req(value) ||
  /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/.test(value);

const valid_color = value =>
  !helpers.req(value) || /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value);

const min_time_as = param => (value, model) => {
  return (
    !helpers.req(value) ||
    (model.hasOwnProperty(param) &&
      date_parse("2000-01-01 " + model[param]).getTime() <=
        date_parse("2000-01-01 " + value).getTime())
  );
};

const check_periods_collide = value => {
  if (!helpers.req(value)) {
    return false;
  }
  if (!Array.isArray(value)) {
    return true;
  }
  if (value.length == 1) {
    return true;
  }

  for (let index1 in value) {
    let check_period = value[index1];
    if (!check_period.from_time || !check_period.to_time) {
      continue;
    }

    let slot1_from_time = date_parse(
      "2000-01-01 " + check_period.from_time
    ).getTime();
    let slot1_to_time = date_parse(
      "2000-01-01 " + check_period.to_time
    ).getTime();

    for (let index2 in value) {
      let against_period = value[index2];
      if (
        index1 == index2 ||
        !against_period.from_time ||
        !against_period.to_time
      ) {
        continue;
      }

      let slot2_from_time = date_parse(
        "2000-01-01 " + against_period.from_time
      ).getTime();
      let slot2_to_time = date_parse(
        "2000-01-01 " + against_period.to_time
      ).getTime();

      if (
        is_two_slots_collide(
          slot1_from_time,
          slot1_to_time,
          slot2_from_time,
          slot2_to_time
        )
      ) {
        return false;
      }
    }
  }
  return true;
};

const is_two_slots_collide = (
  slot1_from_time,
  slot1_to_time,
  slot2_from_time,
  slot2_to_time
) => {
  if (slot1_from_time >= slot2_from_time && slot1_from_time < slot2_to_time) {
    return true;
  }
  if (slot2_from_time >= slot1_from_time && slot2_from_time < slot1_to_time) {
    return true;
  }
  return false;
};

const hex_to_rgb_txt = hex => hex_to_rgb(hex).join(",");

const hex_to_rgb = hex =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16));

const location_prop_by_type = (location_type, prop) => {
  const location_details = {
    physical: {
      name: __("In-person meeting", "wpcal"),
      short_descr: __("Set an address or place", "wpcal")
    },
    phone: {
      name: __("Phone call", "wpcal"),
      short_descr: __("Inbound or Outbound call", "wpcal")
    },
    googlemeet_meeting: {
      name: "Google Meet / Hangouts",
      short_descr: __("Web conference", "wpcal")
    },
    zoom_meeting: {
      name: "Zoom",
      short_descr: __("Web conference", "wpcal")
    },
    gotomeeting_meeting: {
      name: "GoToMeeting",
      short_descr: __("Web conference", "wpcal")
    },
    custom: {
      name: __("Custom", "wpcal"),
      short_descr: __("Leave customized location details", "wpcal")
    },
    ask_invitee: {
      name: __("Ask Invitee", "wpcal"),
      short_descr: __("My Invitee will set the location", "wpcal")
    }
  };
  if (
    location_details.hasOwnProperty(location_type) &&
    location_details[location_type].hasOwnProperty(prop)
  ) {
    return location_details[location_type][prop];
  }
  return location_type;
};

const debug_log = (...strs) => {
  window.wpcal_ajax.is_debug ? console.log(...strs) : "";
};

const date_parse = date_str => {
  let parsed = parseISO(date_str);
  if (parsed === "Invalid Date") {
    return "";
  }
  return parsed;
};

const get_tz_name = tz => {
  let tz_obj = timezone_list.find(tz_obj => {
    return tz_obj.tz === tz;
  });
  if (!tz_obj) {
    return tz.replace("/", " - ").replace("_", " ");
  }
  return tz_obj.name;
};

const weekdays_list = [
  {
    index: 1,
    full_day: /* translators: Weekday. */ __("Monday", "wpcal"),
    mini_day: _x("M", "weekday monday shortest", "wpcal"),
    short_day: /* translators: Three-letter abbreviation of the weekday. */ __(
      "Mon",
      "wpcal"
    )
  },
  {
    index: 2,
    full_day: /* translators: Weekday. */ __("Tuesday", "wpcal"),
    mini_day: _x("Tu", "weekday tuesday shortest", "wpcal"),
    short_day: /* translators: Three-letter abbreviation of the weekday. */ __(
      "Tue",
      "wpcal"
    )
  },
  {
    index: 3,
    full_day: /* translators: Weekday. */ __("Wednesday", "wpcal"),
    mini_day: _x("W", "weekday wednesday shortest", "wpcal"),
    short_day: /* translators: Three-letter abbreviation of the weekday. */ __(
      "Wed",
      "wpcal"
    )
  },
  {
    index: 4,
    full_day: /* translators: Weekday. */ __("Thursday", "wpcal"),
    mini_day: _x("Th", "weekday thursday shortest", "wpcal"),
    short_day: /* translators: Three-letter abbreviation of the weekday. */ __(
      "Thu",
      "wpcal"
    )
  },
  {
    index: 5,
    full_day: /* translators: Weekday. */ __("Friday", "wpcal"),
    mini_day: _x("F", "weekday friday shortest", "wpcal"),
    short_day: /* translators: Three-letter abbreviation of the weekday. */ __(
      "Fri",
      "wpcal"
    )
  },
  {
    index: 6,
    full_day: /* translators: Weekday. */ __("Saturday", "wpcal"),
    mini_day: _x("Sa", "weekday saturday shortest", "wpcal"),
    short_day: /* translators: Three-letter abbreviation of the weekday. */ __(
      "Sat",
      "wpcal"
    )
  },
  {
    index: 7,
    full_day: /* translators: Weekday. */ __("Sunday", "wpcal"),
    mini_day: _x("Su", "weekday sunday shortest", "wpcal"),
    short_day: /* translators: Three-letter abbreviation of the weekday. */ __(
      "Sun",
      "wpcal"
    )
  }
];

const month_list = [
  //this is just here for language scanner deduction
  {
    index: 1,
    full_month: /* translators: Month name. */ __("January", "wpcal"),
    short_month: /* translators: Three-letter abbreviation of the month. */ _x(
      "Jan",
      "three-letter month abbreviation",
      "wpcal"
    )
  },
  {
    index: 2,
    full_month: /* translators: Month name. */ __("February", "wpcal"),
    short_month: /* translators: Three-letter abbreviation of the month. */ _x(
      "Feb",
      "three-letter month abbreviation",
      "wpcal"
    )
  },
  {
    index: 3,
    full_month: /* translators: Month name. */ __("March", "wpcal"),
    short_month: /* translators: Three-letter abbreviation of the month. */ _x(
      "Mar",
      "three-letter month abbreviation",
      "wpcal"
    )
  },
  {
    index: 4,
    full_month: /* translators: Month name. */ __("April", "wpcal"),
    short_month: /* translators: Three-letter abbreviation of the month. */ _x(
      "Apr",
      "three-letter month abbreviation",
      "wpcal"
    )
  },
  {
    index: 5,
    full_month: /* translators: Month name. */ __("May", "wpcal"),
    short_month: /* translators: Three-letter abbreviation of the month. */ _x(
      "May",
      "three-letter month abbreviation",
      "wpcal"
    )
  },
  {
    index: 6,
    full_month: /* translators: Month name. */ __("June", "wpcal"),
    short_month: /* translators: Three-letter abbreviation of the month. */ _x(
      "Jun",
      "three-letter month abbreviation",
      "wpcal"
    )
  },
  {
    index: 7,
    full_month: /* translators: Month name. */ __("July", "wpcal"),
    short_month: /* translators: Three-letter abbreviation of the month. */ _x(
      "Jul",
      "three-letter month abbreviation",
      "wpcal"
    )
  },
  {
    index: 8,
    full_month: /* translators: Month name. */ __("August", "wpcal"),
    short_month: /* translators: Three-letter abbreviation of the month. */ _x(
      "Aug",
      "three-letter month abbreviation",
      "wpcal"
    )
  },
  {
    index: 9,
    full_month: /* translators: Month name. */ __("September", "wpcal"),
    short_month: /* translators: Three-letter abbreviation of the month. */ _x(
      "Sep",
      "three-letter month abbreviation",
      "wpcal"
    )
  },
  {
    index: 10,
    full_month: /* translators: Month name. */ __("October", "wpcal"),
    short_month: /* translators: Three-letter abbreviation of the month. */ _x(
      "Oct",
      "three-letter month abbreviation",
      "wpcal"
    )
  },
  {
    index: 11,
    full_month: /* translators: Month name. */ __("November", "wpcal"),
    short_month: /* translators: Three-letter abbreviation of the month. */ _x(
      "Nov",
      "three-letter month abbreviation",
      "wpcal"
    )
  },
  {
    index: 12,
    full_month: /* translators: Month name. */ __("December", "wpcal"),
    short_month: /* translators: Three-letter abbreviation of the month. */ _x(
      "Dec",
      "three-letter month abbreviation",
      "wpcal"
    )
  }
];

const _lang_support = [
  __("1st", "wpcal"),
  __("2nd", "wpcal"),
  __("3rd", "wpcal"),
  __("4th", "wpcal"),
  __("5th", "wpcal"),
  __("6th", "wpcal"),
  __("7th", "wpcal"),
  __("8th", "wpcal"),
  __("9th", "wpcal"),
  __("10th", "wpcal"),
  __("11th", "wpcal"),
  __("12th", "wpcal"),
  __("13th", "wpcal"),
  __("14th", "wpcal"),
  __("15th", "wpcal"),
  __("16th", "wpcal"),
  __("17th", "wpcal"),
  __("18th", "wpcal"),
  __("19th", "wpcal"),
  __("20th", "wpcal"),
  __("21st", "wpcal"),
  __("22nd", "wpcal"),
  __("23rd", "wpcal"),
  __("24th", "wpcal"),
  __("25th", "wpcal"),
  __("26th", "wpcal"),
  __("27th", "wpcal"),
  __("28th", "wpcal"),
  __("29th", "wpcal"),
  __("30th", "wpcal"),
  __("31st", "wpcal")
];

//a copy of this timezone list in in [plugin]/includes/time_zone_list.json
const timezone_list = [
  { tz: "Africa/Abidjan", name: "Africa - Abidjan" },
  { tz: "Africa/Accra", name: "Africa - Accra" },
  { tz: "Africa/Addis_Ababa", name: "Africa - Addis Ababa" },
  { tz: "Africa/Algiers", name: "Africa - Algiers" },
  { tz: "Africa/Asmara", name: "Africa - Asmara" },
  { tz: "Africa/Bamako", name: "Africa - Bamako" },
  { tz: "Africa/Bangui", name: "Africa - Bangui" },
  { tz: "Africa/Banjul", name: "Africa - Banjul" },
  { tz: "Africa/Bissau", name: "Africa - Bissau" },
  { tz: "Africa/Blantyre", name: "Africa - Blantyre" },
  { tz: "Africa/Brazzaville", name: "Africa - Brazzaville" },
  { tz: "Africa/Bujumbura", name: "Africa - Bujumbura" },
  { tz: "Africa/Cairo", name: "Africa - Cairo" },
  { tz: "Africa/Casablanca", name: "Africa - Casablanca" },
  { tz: "Africa/Ceuta", name: "Africa - Ceuta" },
  { tz: "Africa/Conakry", name: "Africa - Conakry" },
  { tz: "Africa/Dakar", name: "Africa - Dakar" },
  { tz: "Africa/Dar_es_Salaam", name: "Africa - Dar es Salaam" },
  { tz: "Africa/Djibouti", name: "Africa - Djibouti" },
  { tz: "Africa/Douala", name: "Africa - Douala" },
  { tz: "Africa/El_Aaiun", name: "Africa - El Aaiun" },
  { tz: "Africa/Freetown", name: "Africa - Freetown" },
  { tz: "Africa/Gaborone", name: "Africa - Gaborone" },
  { tz: "Africa/Harare", name: "Africa - Harare" },
  { tz: "Africa/Johannesburg", name: "Africa - Johannesburg" },
  { tz: "Africa/Juba", name: "Africa - Juba" },
  { tz: "Africa/Kampala", name: "Africa - Kampala" },
  { tz: "Africa/Khartoum", name: "Africa - Khartoum" },
  { tz: "Africa/Kigali", name: "Africa - Kigali" },
  { tz: "Africa/Kinshasa", name: "Africa - Kinshasa" },
  { tz: "Africa/Lagos", name: "Africa - Lagos" },
  { tz: "Africa/Libreville", name: "Africa - Libreville" },
  { tz: "Africa/Lome", name: "Africa - Lome" },
  { tz: "Africa/Luanda", name: "Africa - Luanda" },
  { tz: "Africa/Lubumbashi", name: "Africa - Lubumbashi" },
  { tz: "Africa/Lusaka", name: "Africa - Lusaka" },
  { tz: "Africa/Malabo", name: "Africa - Malabo" },
  { tz: "Africa/Maputo", name: "Africa - Maputo" },
  { tz: "Africa/Maseru", name: "Africa - Maseru" },
  { tz: "Africa/Mbabane", name: "Africa - Mbabane" },
  { tz: "Africa/Mogadishu", name: "Africa - Mogadishu" },
  { tz: "Africa/Monrovia", name: "Africa - Monrovia" },
  { tz: "Africa/Nairobi", name: "Africa - Nairobi" },
  { tz: "Africa/Ndjamena", name: "Africa - Ndjamena" },
  { tz: "Africa/Niamey", name: "Africa - Niamey" },
  { tz: "Africa/Nouakchott", name: "Africa - Nouakchott" },
  { tz: "Africa/Ouagadougou", name: "Africa - Ouagadougou" },
  { tz: "Africa/Porto-Novo", name: "Africa - Porto-Novo" },
  { tz: "Africa/Sao_Tome", name: "Africa - Sao Tome" },
  { tz: "Africa/Tripoli", name: "Africa - Tripoli" },
  { tz: "Africa/Tunis", name: "Africa - Tunis" },
  { tz: "Africa/Windhoek", name: "Africa - Windhoek" },
  { tz: "America/Adak", name: "America - Adak" },
  { tz: "America/Anchorage", name: "America - Anchorage" },
  { tz: "America/Anguilla", name: "America - Anguilla" },
  { tz: "America/Antigua", name: "America - Antigua" },
  { tz: "America/Araguaina", name: "America - Araguaina" },
  {
    tz: "America/Argentina/Buenos_Aires",
    name: "America - Argentina - Buenos Aires"
  },
  {
    tz: "America/Argentina/Catamarca",
    name: "America - Argentina - Catamarca"
  },
  {
    tz: "America/Argentina/Cordoba",
    name: "America - Argentina - Cordoba"
  },
  { tz: "America/Argentina/Jujuy", name: "America - Argentina - Jujuy" },
  {
    tz: "America/Argentina/La_Rioja",
    name: "America - Argentina - La Rioja"
  },
  {
    tz: "America/Argentina/Mendoza",
    name: "America - Argentina - Mendoza"
  },
  {
    tz: "America/Argentina/Rio_Gallegos",
    name: "America - Argentina - Rio Gallegos"
  },
  { tz: "America/Argentina/Salta", name: "America - Argentina - Salta" },
  {
    tz: "America/Argentina/San_Juan",
    name: "America - Argentina - San Juan"
  },
  {
    tz: "America/Argentina/San_Luis",
    name: "America - Argentina - San Luis"
  },
  {
    tz: "America/Argentina/Tucuman",
    name: "America - Argentina - Tucuman"
  },
  {
    tz: "America/Argentina/Ushuaia",
    name: "America - Argentina - Ushuaia"
  },
  { tz: "America/Aruba", name: "America - Aruba" },
  { tz: "America/Asuncion", name: "America - Asuncion" },
  { tz: "America/Atikokan", name: "America - Atikokan" },
  { tz: "America/Bahia", name: "America - Bahia" },
  { tz: "America/Bahia_Banderas", name: "America - Bahia Banderas" },
  { tz: "America/Barbados", name: "America - Barbados" },
  { tz: "America/Belem", name: "America - Belem" },
  { tz: "America/Belize", name: "America - Belize" },
  { tz: "America/Blanc-Sablon", name: "America - Blanc-Sablon" },
  { tz: "America/Boa_Vista", name: "America - Boa Vista" },
  { tz: "America/Bogota", name: "America - Bogota" },
  { tz: "America/Boise", name: "America - Boise" },
  { tz: "America/Cambridge_Bay", name: "America - Cambridge Bay" },
  { tz: "America/Campo_Grande", name: "America - Campo Grande" },
  { tz: "America/Cancun", name: "America - Cancun" },
  { tz: "America/Caracas", name: "America - Caracas" },
  { tz: "America/Cayenne", name: "America - Cayenne" },
  { tz: "America/Cayman", name: "America - Cayman" },
  { tz: "America/Chicago", name: "America - Chicago" },
  { tz: "America/Chihuahua", name: "America - Chihuahua" },
  { tz: "America/Costa_Rica", name: "America - Costa Rica" },
  { tz: "America/Creston", name: "America - Creston" },
  { tz: "America/Cuiaba", name: "America - Cuiaba" },
  { tz: "America/Curacao", name: "America - Curacao" },
  { tz: "America/Danmarkshavn", name: "America - Danmarkshavn" },
  { tz: "America/Dawson", name: "America - Dawson" },
  { tz: "America/Dawson_Creek", name: "America - Dawson Creek" },
  { tz: "America/Denver", name: "America - Denver" },
  { tz: "America/Detroit", name: "America - Detroit" },
  { tz: "America/Dominica", name: "America - Dominica" },
  { tz: "America/Edmonton", name: "America - Edmonton" },
  { tz: "America/Eirunepe", name: "America - Eirunepe" },
  { tz: "America/El_Salvador", name: "America - El Salvador" },
  { tz: "America/Fortaleza", name: "America - Fortaleza" },
  { tz: "America/Fort_Nelson", name: "America - Fort Nelson" },
  { tz: "America/Glace_Bay", name: "America - Glace Bay" },
  { tz: "America/Godthab", name: "America - Godthab" },
  { tz: "America/Goose_Bay", name: "America - Goose Bay" },
  { tz: "America/Grand_Turk", name: "America - Grand Turk" },
  { tz: "America/Grenada", name: "America - Grenada" },
  { tz: "America/Guadeloupe", name: "America - Guadeloupe" },
  { tz: "America/Guatemala", name: "America - Guatemala" },
  { tz: "America/Guayaquil", name: "America - Guayaquil" },
  { tz: "America/Guyana", name: "America - Guyana" },
  { tz: "America/Halifax", name: "America - Halifax" },
  { tz: "America/Havana", name: "America - Havana" },
  { tz: "America/Hermosillo", name: "America - Hermosillo" },
  {
    tz: "America/Indiana/Indianapolis",
    name: "America - Indiana - Indianapolis"
  },
  { tz: "America/Indiana/Knox", name: "America - Indiana - Knox" },
  { tz: "America/Indiana/Marengo", name: "America - Indiana - Marengo" },
  {
    tz: "America/Indiana/Petersburg",
    name: "America - Indiana - Petersburg"
  },
  {
    tz: "America/Indiana/Tell_City",
    name: "America - Indiana - Tell City"
  },
  { tz: "America/Indiana/Vevay", name: "America - Indiana - Vevay" },
  {
    tz: "America/Indiana/Vincennes",
    name: "America - Indiana - Vincennes"
  },
  { tz: "America/Indiana/Winamac", name: "America - Indiana - Winamac" },
  { tz: "America/Inuvik", name: "America - Inuvik" },
  { tz: "America/Iqaluit", name: "America - Iqaluit" },
  { tz: "America/Jamaica", name: "America - Jamaica" },
  { tz: "America/Juneau", name: "America - Juneau" },
  {
    tz: "America/Kentucky/Louisville",
    name: "America - Kentucky - Louisville"
  },
  {
    tz: "America/Kentucky/Monticello",
    name: "America - Kentucky - Monticello"
  },
  { tz: "America/Kralendijk", name: "America - Kralendijk" },
  { tz: "America/La_Paz", name: "America - La Paz" },
  { tz: "America/Lima", name: "America - Lima" },
  { tz: "America/Los_Angeles", name: "America - Los Angeles" },
  { tz: "America/Lower_Princes", name: "America - Lower Princes" },
  { tz: "America/Maceio", name: "America - Maceio" },
  { tz: "America/Managua", name: "America - Managua" },
  { tz: "America/Manaus", name: "America - Manaus" },
  { tz: "America/Marigot", name: "America - Marigot" },
  { tz: "America/Martinique", name: "America - Martinique" },
  { tz: "America/Matamoros", name: "America - Matamoros" },
  { tz: "America/Mazatlan", name: "America - Mazatlan" },
  { tz: "America/Menominee", name: "America - Menominee" },
  { tz: "America/Merida", name: "America - Merida" },
  { tz: "America/Metlakatla", name: "America - Metlakatla" },
  { tz: "America/Mexico_City", name: "America - Mexico City" },
  { tz: "America/Miquelon", name: "America - Miquelon" },
  { tz: "America/Moncton", name: "America - Moncton" },
  { tz: "America/Monterrey", name: "America - Monterrey" },
  { tz: "America/Montevideo", name: "America - Montevideo" },
  { tz: "America/Montserrat", name: "America - Montserrat" },
  { tz: "America/Nassau", name: "America - Nassau" },
  { tz: "America/New_York", name: "America - New York" },
  { tz: "America/Nipigon", name: "America - Nipigon" },
  { tz: "America/Nome", name: "America - Nome" },
  { tz: "America/Noronha", name: "America - Noronha" },
  {
    tz: "America/North_Dakota/Beulah",
    name: "America - North Dakota - Beulah"
  },
  {
    tz: "America/North_Dakota/Center",
    name: "America - North Dakota - Center"
  },
  {
    tz: "America/North_Dakota/New_Salem",
    name: "America - North Dakota - New Salem"
  },
  { tz: "America/Ojinaga", name: "America - Ojinaga" },
  { tz: "America/Panama", name: "America - Panama" },
  { tz: "America/Pangnirtung", name: "America - Pangnirtung" },
  { tz: "America/Paramaribo", name: "America - Paramaribo" },
  { tz: "America/Phoenix", name: "America - Phoenix" },
  { tz: "America/Port-au-Prince", name: "America - Port-au-Prince" },
  { tz: "America/Port_of_Spain", name: "America - Port of Spain" },
  { tz: "America/Porto_Velho", name: "America - Porto Velho" },
  { tz: "America/Puerto_Rico", name: "America - Puerto Rico" },
  { tz: "America/Punta_Arenas", name: "America - Punta Arenas" },
  { tz: "America/Rainy_River", name: "America - Rainy River" },
  { tz: "America/Rankin_Inlet", name: "America - Rankin Inlet" },
  { tz: "America/Recife", name: "America - Recife" },
  { tz: "America/Regina", name: "America - Regina" },
  { tz: "America/Resolute", name: "America - Resolute" },
  { tz: "America/Rio_Branco", name: "America - Rio Branco" },
  { tz: "America/Santarem", name: "America - Santarem" },
  { tz: "America/Santiago", name: "America - Santiago" },
  { tz: "America/Santo_Domingo", name: "America - Santo Domingo" },
  { tz: "America/Sao_Paulo", name: "America - Sao Paulo" },
  { tz: "America/Scoresbysund", name: "America - Scoresbysund" },
  { tz: "America/Sitka", name: "America - Sitka" },
  { tz: "America/St_Barthelemy", name: "America - St Barthelemy" },
  { tz: "America/St_Johns", name: "America - St Johns" },
  { tz: "America/St_Kitts", name: "America - St Kitts" },
  { tz: "America/St_Lucia", name: "America - St Lucia" },
  { tz: "America/St_Thomas", name: "America - St Thomas" },
  { tz: "America/St_Vincent", name: "America - St Vincent" },
  { tz: "America/Swift_Current", name: "America - Swift Current" },
  { tz: "America/Tegucigalpa", name: "America - Tegucigalpa" },
  { tz: "America/Thule", name: "America - Thule" },
  { tz: "America/Thunder_Bay", name: "America - Thunder Bay" },
  { tz: "America/Tijuana", name: "America - Tijuana" },
  { tz: "America/Toronto", name: "America - Toronto" },
  { tz: "America/Tortola", name: "America - Tortola" },
  { tz: "America/Vancouver", name: "America - Vancouver" },
  { tz: "America/Whitehorse", name: "America - Whitehorse" },
  { tz: "America/Winnipeg", name: "America - Winnipeg" },
  { tz: "America/Yakutat", name: "America - Yakutat" },
  { tz: "America/Yellowknife", name: "America - Yellowknife" },
  { tz: "Antarctica/Casey", name: "Antarctica - Casey" },
  { tz: "Antarctica/Davis", name: "Antarctica - Davis" },
  {
    tz: "Antarctica/DumontDUrville",
    name: "Antarctica - DumontDUrville"
  },
  { tz: "Antarctica/Macquarie", name: "Antarctica - Macquarie" },
  { tz: "Antarctica/Mawson", name: "Antarctica - Mawson" },
  { tz: "Antarctica/McMurdo", name: "Antarctica - McMurdo" },
  { tz: "Antarctica/Palmer", name: "Antarctica - Palmer" },
  { tz: "Antarctica/Rothera", name: "Antarctica - Rothera" },
  { tz: "Antarctica/Syowa", name: "Antarctica - Syowa" },
  { tz: "Antarctica/Troll", name: "Antarctica - Troll" },
  { tz: "Antarctica/Vostok", name: "Antarctica - Vostok" },
  { tz: "Arctic/Longyearbyen", name: "Arctic - Longyearbyen" },
  { tz: "Asia/Aden", name: "Asia - Aden" },
  { tz: "Asia/Almaty", name: "Asia - Almaty" },
  { tz: "Asia/Amman", name: "Asia - Amman" },
  { tz: "Asia/Anadyr", name: "Asia - Anadyr" },
  { tz: "Asia/Aqtau", name: "Asia - Aqtau" },
  { tz: "Asia/Aqtobe", name: "Asia - Aqtobe" },
  { tz: "Asia/Ashgabat", name: "Asia - Ashgabat" },
  { tz: "Asia/Atyrau", name: "Asia - Atyrau" },
  { tz: "Asia/Baghdad", name: "Asia - Baghdad" },
  { tz: "Asia/Bahrain", name: "Asia - Bahrain" },
  { tz: "Asia/Baku", name: "Asia - Baku" },
  { tz: "Asia/Bangkok", name: "Asia - Bangkok" },
  { tz: "Asia/Barnaul", name: "Asia - Barnaul" },
  { tz: "Asia/Beirut", name: "Asia - Beirut" },
  { tz: "Asia/Bishkek", name: "Asia - Bishkek" },
  { tz: "Asia/Brunei", name: "Asia - Brunei" },
  { tz: "Asia/Chita", name: "Asia - Chita" },
  { tz: "Asia/Choibalsan", name: "Asia - Choibalsan" },
  { tz: "Asia/Colombo", name: "Asia - Colombo" },
  { tz: "Asia/Damascus", name: "Asia - Damascus" },
  { tz: "Asia/Dhaka", name: "Asia - Dhaka" },
  { tz: "Asia/Dili", name: "Asia - Dili" },
  { tz: "Asia/Dubai", name: "Asia - Dubai" },
  { tz: "Asia/Dushanbe", name: "Asia - Dushanbe" },
  { tz: "Asia/Famagusta", name: "Asia - Famagusta" },
  { tz: "Asia/Gaza", name: "Asia - Gaza" },
  { tz: "Asia/Hebron", name: "Asia - Hebron" },
  { tz: "Asia/Ho_Chi_Minh", name: "Asia - Ho Chi Minh" },
  { tz: "Asia/Hong_Kong", name: "Asia - Hong Kong" },
  { tz: "Asia/Hovd", name: "Asia - Hovd" },
  { tz: "Asia/Irkutsk", name: "Asia - Irkutsk" },
  { tz: "Asia/Jakarta", name: "Asia - Jakarta" },
  { tz: "Asia/Jayapura", name: "Asia - Jayapura" },
  { tz: "Asia/Jerusalem", name: "Asia - Jerusalem" },
  { tz: "Asia/Kabul", name: "Asia - Kabul" },
  { tz: "Asia/Kamchatka", name: "Asia - Kamchatka" },
  { tz: "Asia/Karachi", name: "Asia - Karachi" },
  { tz: "Asia/Kathmandu", name: "Asia - Kathmandu" },
  { tz: "Asia/Khandyga", name: "Asia - Khandyga" },
  { tz: "Asia/Kolkata", name: "Asia - Kolkata" },
  { tz: "Asia/Krasnoyarsk", name: "Asia - Krasnoyarsk" },
  { tz: "Asia/Kuala_Lumpur", name: "Asia - Kuala Lumpur" },
  { tz: "Asia/Kuching", name: "Asia - Kuching" },
  { tz: "Asia/Kuwait", name: "Asia - Kuwait" },
  { tz: "Asia/Macau", name: "Asia - Macau" },
  { tz: "Asia/Magadan", name: "Asia - Magadan" },
  { tz: "Asia/Makassar", name: "Asia - Makassar" },
  { tz: "Asia/Manila", name: "Asia - Manila" },
  { tz: "Asia/Muscat", name: "Asia - Muscat" },
  { tz: "Asia/Nicosia", name: "Asia - Nicosia" },
  { tz: "Asia/Novokuznetsk", name: "Asia - Novokuznetsk" },
  { tz: "Asia/Novosibirsk", name: "Asia - Novosibirsk" },
  { tz: "Asia/Omsk", name: "Asia - Omsk" },
  { tz: "Asia/Oral", name: "Asia - Oral" },
  { tz: "Asia/Phnom_Penh", name: "Asia - Phnom Penh" },
  { tz: "Asia/Pontianak", name: "Asia - Pontianak" },
  { tz: "Asia/Pyongyang", name: "Asia - Pyongyang" },
  { tz: "Asia/Qatar", name: "Asia - Qatar" },
  { tz: "Asia/Qostanay", name: "Asia - Qostanay" },
  { tz: "Asia/Qyzylorda", name: "Asia - Qyzylorda" },
  { tz: "Asia/Riyadh", name: "Asia - Riyadh" },
  { tz: "Asia/Sakhalin", name: "Asia - Sakhalin" },
  { tz: "Asia/Samarkand", name: "Asia - Samarkand" },
  { tz: "Asia/Seoul", name: "Asia - Seoul" },
  { tz: "Asia/Shanghai", name: "Asia - Shanghai" },
  { tz: "Asia/Singapore", name: "Asia - Singapore" },
  { tz: "Asia/Srednekolymsk", name: "Asia - Srednekolymsk" },
  { tz: "Asia/Taipei", name: "Asia - Taipei" },
  { tz: "Asia/Tashkent", name: "Asia - Tashkent" },
  { tz: "Asia/Tbilisi", name: "Asia - Tbilisi" },
  { tz: "Asia/Tehran", name: "Asia - Tehran" },
  { tz: "Asia/Thimphu", name: "Asia - Thimphu" },
  { tz: "Asia/Tokyo", name: "Asia - Tokyo" },
  { tz: "Asia/Tomsk", name: "Asia - Tomsk" },
  { tz: "Asia/Ulaanbaatar", name: "Asia - Ulaanbaatar" },
  { tz: "Asia/Urumqi", name: "Asia - Urumqi" },
  { tz: "Asia/Ust-Nera", name: "Asia - Ust-Nera" },
  { tz: "Asia/Vientiane", name: "Asia - Vientiane" },
  { tz: "Asia/Vladivostok", name: "Asia - Vladivostok" },
  { tz: "Asia/Yakutsk", name: "Asia - Yakutsk" },
  { tz: "Asia/Yangon", name: "Asia - Yangon" },
  { tz: "Asia/Yekaterinburg", name: "Asia - Yekaterinburg" },
  { tz: "Asia/Yerevan", name: "Asia - Yerevan" },
  { tz: "Atlantic/Azores", name: "Atlantic - Azores" },
  { tz: "Atlantic/Bermuda", name: "Atlantic - Bermuda" },
  { tz: "Atlantic/Canary", name: "Atlantic - Canary" },
  { tz: "Atlantic/Cape_Verde", name: "Atlantic - Cape Verde" },
  { tz: "Atlantic/Faroe", name: "Atlantic - Faroe" },
  { tz: "Atlantic/Madeira", name: "Atlantic - Madeira" },
  { tz: "Atlantic/Reykjavik", name: "Atlantic - Reykjavik" },
  { tz: "Atlantic/South_Georgia", name: "Atlantic - South Georgia" },
  { tz: "Atlantic/Stanley", name: "Atlantic - Stanley" },
  { tz: "Atlantic/St_Helena", name: "Atlantic - St Helena" },
  { tz: "Australia/Adelaide", name: "Australia - Adelaide" },
  { tz: "Australia/Brisbane", name: "Australia - Brisbane" },
  { tz: "Australia/Broken_Hill", name: "Australia - Broken Hill" },
  { tz: "Australia/Currie", name: "Australia - Currie" },
  { tz: "Australia/Darwin", name: "Australia - Darwin" },
  { tz: "Australia/Eucla", name: "Australia - Eucla" },
  { tz: "Australia/Hobart", name: "Australia - Hobart" },
  { tz: "Australia/Lindeman", name: "Australia - Lindeman" },
  { tz: "Australia/Lord_Howe", name: "Australia - Lord Howe" },
  { tz: "Australia/Melbourne", name: "Australia - Melbourne" },
  { tz: "Australia/Perth", name: "Australia - Perth" },
  { tz: "Australia/Sydney", name: "Australia - Sydney" },
  { tz: "Europe/Amsterdam", name: "Europe - Amsterdam" },
  { tz: "Europe/Andorra", name: "Europe - Andorra" },
  { tz: "Europe/Astrakhan", name: "Europe - Astrakhan" },
  { tz: "Europe/Athens", name: "Europe - Athens" },
  { tz: "Europe/Belgrade", name: "Europe - Belgrade" },
  { tz: "Europe/Berlin", name: "Europe - Berlin" },
  { tz: "Europe/Bratislava", name: "Europe - Bratislava" },
  { tz: "Europe/Brussels", name: "Europe - Brussels" },
  { tz: "Europe/Bucharest", name: "Europe - Bucharest" },
  { tz: "Europe/Budapest", name: "Europe - Budapest" },
  { tz: "Europe/Busingen", name: "Europe - Busingen" },
  { tz: "Europe/Chisinau", name: "Europe - Chisinau" },
  { tz: "Europe/Copenhagen", name: "Europe - Copenhagen" },
  { tz: "Europe/Dublin", name: "Europe - Dublin" },
  { tz: "Europe/Gibraltar", name: "Europe - Gibraltar" },
  { tz: "Europe/Guernsey", name: "Europe - Guernsey" },
  { tz: "Europe/Helsinki", name: "Europe - Helsinki" },
  { tz: "Europe/Isle_of_Man", name: "Europe - Isle of Man" },
  { tz: "Europe/Istanbul", name: "Europe - Istanbul" },
  { tz: "Europe/Jersey", name: "Europe - Jersey" },
  { tz: "Europe/Kaliningrad", name: "Europe - Kaliningrad" },
  { tz: "Europe/Kiev", name: "Europe - Kiev" },
  { tz: "Europe/Kirov", name: "Europe - Kirov" },
  { tz: "Europe/Lisbon", name: "Europe - Lisbon" },
  { tz: "Europe/Ljubljana", name: "Europe - Ljubljana" },
  { tz: "Europe/London", name: "Europe - London" },
  { tz: "Europe/Luxembourg", name: "Europe - Luxembourg" },
  { tz: "Europe/Madrid", name: "Europe - Madrid" },
  { tz: "Europe/Malta", name: "Europe - Malta" },
  { tz: "Europe/Mariehamn", name: "Europe - Mariehamn" },
  { tz: "Europe/Minsk", name: "Europe - Minsk" },
  { tz: "Europe/Monaco", name: "Europe - Monaco" },
  { tz: "Europe/Moscow", name: "Europe - Moscow" },
  { tz: "Europe/Oslo", name: "Europe - Oslo" },
  { tz: "Europe/Paris", name: "Europe - Paris" },
  { tz: "Europe/Podgorica", name: "Europe - Podgorica" },
  { tz: "Europe/Prague", name: "Europe - Prague" },
  { tz: "Europe/Riga", name: "Europe - Riga" },
  { tz: "Europe/Rome", name: "Europe - Rome" },
  { tz: "Europe/Samara", name: "Europe - Samara" },
  { tz: "Europe/San_Marino", name: "Europe - San Marino" },
  { tz: "Europe/Sarajevo", name: "Europe - Sarajevo" },
  { tz: "Europe/Saratov", name: "Europe - Saratov" },
  { tz: "Europe/Simferopol", name: "Europe - Simferopol" },
  { tz: "Europe/Skopje", name: "Europe - Skopje" },
  { tz: "Europe/Sofia", name: "Europe - Sofia" },
  { tz: "Europe/Stockholm", name: "Europe - Stockholm" },
  { tz: "Europe/Tallinn", name: "Europe - Tallinn" },
  { tz: "Europe/Tirane", name: "Europe - Tirane" },
  { tz: "Europe/Ulyanovsk", name: "Europe - Ulyanovsk" },
  { tz: "Europe/Uzhgorod", name: "Europe - Uzhgorod" },
  { tz: "Europe/Vaduz", name: "Europe - Vaduz" },
  { tz: "Europe/Vatican", name: "Europe - Vatican" },
  { tz: "Europe/Vienna", name: "Europe - Vienna" },
  { tz: "Europe/Vilnius", name: "Europe - Vilnius" },
  { tz: "Europe/Volgograd", name: "Europe - Volgograd" },
  { tz: "Europe/Warsaw", name: "Europe - Warsaw" },
  { tz: "Europe/Zagreb", name: "Europe - Zagreb" },
  { tz: "Europe/Zaporozhye", name: "Europe - Zaporozhye" },
  { tz: "Europe/Zurich", name: "Europe - Zurich" },
  { tz: "Indian/Antananarivo", name: "Indian - Antananarivo" },
  { tz: "Indian/Chagos", name: "Indian - Chagos" },
  { tz: "Indian/Christmas", name: "Indian - Christmas" },
  { tz: "Indian/Cocos", name: "Indian - Cocos" },
  { tz: "Indian/Comoro", name: "Indian - Comoro" },
  { tz: "Indian/Kerguelen", name: "Indian - Kerguelen" },
  { tz: "Indian/Mahe", name: "Indian - Mahe" },
  { tz: "Indian/Maldives", name: "Indian - Maldives" },
  { tz: "Indian/Mauritius", name: "Indian - Mauritius" },
  { tz: "Indian/Mayotte", name: "Indian - Mayotte" },
  { tz: "Indian/Reunion", name: "Indian - Reunion" },
  { tz: "Pacific/Apia", name: "Pacific - Apia" },
  { tz: "Pacific/Auckland", name: "Pacific - Auckland" },
  { tz: "Pacific/Bougainville", name: "Pacific - Bougainville" },
  { tz: "Pacific/Chatham", name: "Pacific - Chatham" },
  { tz: "Pacific/Chuuk", name: "Pacific - Chuuk" },
  { tz: "Pacific/Easter", name: "Pacific - Easter" },
  { tz: "Pacific/Efate", name: "Pacific - Efate" },
  { tz: "Pacific/Enderbury", name: "Pacific - Enderbury" },
  { tz: "Pacific/Fakaofo", name: "Pacific - Fakaofo" },
  { tz: "Pacific/Fiji", name: "Pacific - Fiji" },
  { tz: "Pacific/Funafuti", name: "Pacific - Funafuti" },
  { tz: "Pacific/Galapagos", name: "Pacific - Galapagos" },
  { tz: "Pacific/Gambier", name: "Pacific - Gambier" },
  { tz: "Pacific/Guadalcanal", name: "Pacific - Guadalcanal" },
  { tz: "Pacific/Guam", name: "Pacific - Guam" },
  { tz: "Pacific/Honolulu", name: "Pacific - Honolulu" },
  { tz: "Pacific/Kiritimati", name: "Pacific - Kiritimati" },
  { tz: "Pacific/Kosrae", name: "Pacific - Kosrae" },
  { tz: "Pacific/Kwajalein", name: "Pacific - Kwajalein" },
  { tz: "Pacific/Majuro", name: "Pacific - Majuro" },
  { tz: "Pacific/Marquesas", name: "Pacific - Marquesas" },
  { tz: "Pacific/Midway", name: "Pacific - Midway" },
  { tz: "Pacific/Nauru", name: "Pacific - Nauru" },
  { tz: "Pacific/Niue", name: "Pacific - Niue" },
  { tz: "Pacific/Norfolk", name: "Pacific - Norfolk" },
  { tz: "Pacific/Noumea", name: "Pacific - Noumea" },
  { tz: "Pacific/Pago_Pago", name: "Pacific - Pago Pago" },
  { tz: "Pacific/Palau", name: "Pacific - Palau" },
  { tz: "Pacific/Pitcairn", name: "Pacific - Pitcairn" },
  { tz: "Pacific/Pohnpei", name: "Pacific - Pohnpei" },
  { tz: "Pacific/Port_Moresby", name: "Pacific - Port Moresby" },
  { tz: "Pacific/Rarotonga", name: "Pacific - Rarotonga" },
  { tz: "Pacific/Saipan", name: "Pacific - Saipan" },
  { tz: "Pacific/Tahiti", name: "Pacific - Tahiti" },
  { tz: "Pacific/Tarawa", name: "Pacific - Tarawa" },
  { tz: "Pacific/Tongatapu", name: "Pacific - Tongatapu" },
  { tz: "Pacific/Wake", name: "Pacific - Wake" },
  { tz: "Pacific/Wallis", name: "Pacific - Wallis" }
];
