import { _lang } from "@/utils/common_func.js";
// _lang - to avoid name changed by webpack

import customValidationFormGroup from "@/components/form_elements/ValidationFormGroup.vue";

export default {
  name: "form-row",
  template: customValidationFormGroup, // you can also pass your own custom template
  messages: {
    // error messages to use
    // required: "This {attribute} field is required1.",
    // email: "This {attribute} field must a valid email.",
    // integer: "This {attribute} field must be a number.",
    required: _lang.__("This field is required.", "wpcal"),
    email: _lang.__("This field must a valid email.", "wpcal"),
    integer: _lang.__("This field must be a number.", "wpcal"),
    between_duration: _lang.T__(
      "This field must be between 1 and 1440 (including).",
      "wpcal"
    ),
    between_display_start_time_every: _lang.T__(
      "This field must be between 1 and 1440 (including).",
      "wpcal"
    ),
    between_max_booking_per_day: _lang.T__(
      "This field must be between 1 and 1440 (including).",
      "wpcal"
    ),
    between_event_buffer_before: _lang.__(
      "Please select valid field.",
      "wpcal"
    ),
    between_event_buffer_before: _lang.__(
      "Please select valid field.",
      "wpcal"
    ),
    min_date: _lang.T__(
      "Too bad, we can't time travel yet. The TO date should be later than the FROM date.",
      "wpcal"
    ),
    min_time: _lang.T__(
      "Too bad, we can't time travel yet. The TO time should be later than the FROM time.",
      "wpcal"
    ),
    valid_date: _lang.__("This field date must be valid.", "wpcal"),
    valid_time: _lang.__("This field time must be valid.", "wpcal"),
    check_periods_collide: _lang.T__(
      "This time periods clashing with each other.",
      "wpcal"
    ),
    must_be_1_or_0: _lang.T__("This field value must be 1 or 0.", "wpcal"),
    in_array_time_format: _lang.T__(
      "This field value must be 12hrs or 24hrs.",
      "wpcal"
    ),
    subset_working_days: _lang.T__(
      "This field value must be between 1 and 7(including).",
      "wpcal"
    ),
    //max_char_length_150: "This field can contain a maximum of 150 characters.",
    max_char_length_500: _lang.__(
      "This field can contain a maximum of 500 characters.",
      "wpcal"
    ),
    valid_color: _lang.T__(
      "Please enter a valid hex color code. Eg. #eee, #eeeeee.",
      "wpcal"
    ),
    branding_font_in_array: _lang.__("Invalid selection value.", "wpcal"),
    use_wp_mail_in_array: _lang.__("Invalid selection value.", "wpcal"),
    hide_premium_info_badges_in_array: _lang.__(
      "Invalid selection value.",
      "wpcal"
    ),
    valid_phone: _lang.__(
      "This field value must be valid phone number.",
      "wpcal"
    ),
    valid_phone_if: _lang.__(
      "This field value must be valid phone number.",
      "wpcal"
    )
  },
  attributes: {
    // maps form keys to actual field names
    email: "Email",
    first_name: "First name",
    last_name: "Last name"
  }
};
