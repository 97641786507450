import Vue from "vue";
import { format, fromUnixTime, differenceInHours } from "date-fns";
import { format as tz_format, toDate, utcToZonedTime } from "date-fns-tz";
import {
  get_tz_name,
  location_prop_by_type,
  date_parse,
  __,
  _x,
  _n,
  _nx,
  T__,
  T_x,
  T_n,
  T_nx,
  Tsprintf,
  weekdays_list
} from "@/utils/common_func.js";

Vue.filter("wpcal_format_time_only", function(value, time_format = "HH:mm") {
  if (!value) {
    return "";
  }
  if (value && !(value instanceof Date)) {
    value = date_parse("2000-01-01 " + value);
  }
  let return_value = format(value, time_format);
  return return_value;
});

Vue.filter("wpcal_format_std_date", function(value) {
  if (!value) {
    return "";
  }
  if (!(value instanceof Date)) {
    value = date_parse(value);
  }
  let return_value = format(value, "yyyy-MM-dd");
  return return_value;
});

Vue.filter("wpcal_format_date_style1", function(value) {
  if (!value) {
    return "";
  }
  if (!(value instanceof Date)) {
    value = date_parse(value);
  }
  let return_value =
    _x(format(value, "MMM"), "three-letter month abbreviation", "wpcal") +
    " " +
    format(value, "d, yyyy"); //"MMM d, yyyy" -- "MMM"  is Jan, Feb, ..., Dec
  return return_value;
});

Vue.filter("wpcal_format_full_date_day", function(value) {
  if (!value) {
    return "";
  }
  if (!(value instanceof Date)) {
    value = date_parse(value);
  }
  let return_value =
    __(format(value, "EEEE"), "wpcal") +
    ", " +
    __(format(value, "do"), "wpcal") +
    " " +
    __(format(value, "LLLL"), "wpcal") +
    ", " +
    format(value, "yyyy"); //"EEEE, do LLLL, yyyy" -- "EEEE"  is Monday, Tuesday, ..., Sunday | "LLLL" is January, February, ..., December | do	is 1st, 2nd, ..., 31st
  return return_value;
});

Vue.filter("wpcal_format_fw_fm_dth_y", function(value) {
  if (!value) {
    return "";
  }
  if (!(value instanceof Date)) {
    value = date_parse(value);
  }
  let format_str = "EEEE, LLLL do";
  let return_value =
    __(format(value, "EEEE"), "wpcal") +
    ", " +
    __(format(value, "LLLL"), "wpcal") +
    " " +
    __(format(value, "do"), "wpcal"); //"EEEE, LLLL do" -- "EEEE"  is Monday, Tuesday, ..., Sunday | "LLLL" is January, February, ..., December | do	is 1st, 2nd, ..., 31st
  if (new Date().getFullYear() != value.getFullYear()) {
    format_str = "EEEE, LLLL do, yyyy";
    return_value += format(value, ", yyyy");
  }

  return return_value;
});

Vue.filter("wpcal_convert_tz_std_date_to_month_year_obj", function(
  value,
  from_tz,
  to_tz
) {
  if (!value) {
    return "";
  }

  let date_from_tz = toDate(value, { timeZone: from_tz });
  let date_to_tz = utcToZonedTime(date_from_tz, to_tz);

  let month = tz_format(date_to_tz, "L", { timeZone: to_tz });
  let year = tz_format(date_to_tz, "yyyy", { timeZone: to_tz });

  let month_year_obj = { month: parseInt(month), year: parseInt(year) };

  return month_year_obj;
});

Vue.filter("wpcal_unix_to_time", function(value, tz, time_format = "HH:mm") {
  if (!value) {
    return "";
  }
  if (!(value instanceof Date)) {
    value = fromUnixTime(value);
  }

  value = utcToZonedTime(value, tz);

  let return_value = tz_format(value, time_format, { timeZone: tz });
  return return_value;
});

Vue.filter("wpcal_unix_to_date", function(value, tz) {
  if (!value) {
    return "";
  }
  if (!(value instanceof Date)) {
    value = fromUnixTime(value);
  }

  value = utcToZonedTime(value, tz);

  let return_value = tz_format(value, "yyyy-MM-dd", { timeZone: tz });
  return return_value;
});

Vue.filter("wpcal_unix_to_sm_sd_if_fy", function(value, tz) {
  if (!value) {
    return "";
  }
  if (!(value instanceof Date)) {
    value = fromUnixTime(value);
  }

  value = utcToZonedTime(value, tz);

  let format_str = "MMM d";
  let return_value =
    _x(
      tz_format(value, "MMM", { timeZone: tz }),
      "three-letter month abbreviation",
      "wpcal"
    ) +
    " " +
    tz_format(value, "d", { timeZone: tz }); //"MMM d" -- "MMM"  is Jan, Feb, ..., Dec

  if (new Date().getFullYear() != value.getFullYear()) {
    format_str = "MMM d, yyyy";
    return_value += tz_format(value, ", yyyy", { timeZone: tz });
  }
  return return_value;
});

const helper_plain_unix_to_full_date_day = function(value, tz) {
  let return_value =
    __(tz_format(value, "EEEE", { timeZone: tz }), "wpcal") +
    ", " +
    __(tz_format(value, "do", { timeZone: tz }), "wpcal") +
    " " +
    __(tz_format(value, "LLLL", { timeZone: tz }), "wpcal") +
    ", " +
    tz_format(value, "yyyy", { timeZone: tz }); //"EEEE, do LLLL, yyyy" -- "EEEE"  is Monday, Tuesday, ..., Sunday | "LLLL" is January, February, ..., December | do	is 1st, 2nd, ..., 31st
  return return_value;
};

Vue.filter("wpcal_unix_to_full_date_day", function(value, tz) {
  if (!value) {
    return "";
  }
  if (!(value instanceof Date)) {
    value = fromUnixTime(value);
  }

  value = utcToZonedTime(value, tz);

  let return_value = helper_plain_unix_to_full_date_day(value, tz);

  let tz_name = get_tz_name(tz);
  return_value += " (" + tz_name + ")";
  return return_value;
});

Vue.filter("wpcal_unix_to_from_to_time_full_date_day_tz", function(
  from_time,
  to_time,
  tz,
  time_format = "HH:mm"
) {
  if (!from_time || !to_time) {
    return "";
  }
  if (!(from_time instanceof Date)) {
    from_time = fromUnixTime(from_time);
  }
  if (!(to_time instanceof Date)) {
    to_time = fromUnixTime(to_time);
  }

  from_time = utcToZonedTime(from_time, tz);
  to_time = utcToZonedTime(to_time, tz);

  let date_std = "yyyy-MM-dd";
  let date1_std = tz_format(from_time, date_std, { timeZone: tz });
  let date2_std = tz_format(to_time, date_std, { timeZone: tz });

  let date_long_format = "EEEE, do LLLL, yyyy";
  let from_time_format = time_format;
  let from_time_display = tz_format(from_time, from_time_format, {
    timeZone: tz
  });
  let to_time_format = time_format + ", " + date_long_format;
  let to_time_display =
    tz_format(to_time, time_format, {
      timeZone: tz
    }) +
    ",<br>" +
    helper_plain_unix_to_full_date_day(to_time, tz);

  if (date1_std != date2_std) {
    from_time_format = time_format + ", " + date_long_format;
    from_time_display =
      tz_format(from_time, time_format, {
        timeZone: tz
      }) +
      ", " +
      helper_plain_unix_to_full_date_day(from_time, tz);
  }

  // let from_time_display = tz_format(from_time, from_time_format, {
  //   timeZone: tz
  // });
  // let to_time_display = tz_format(to_time, to_time_format, { timeZone: tz });

  let tz_name = get_tz_name(tz);
  let return_value =
    from_time_display + " - " + to_time_display + "<br>(" + tz_name + ")";
  return return_value;
});

Vue.filter("wpcal_unix_to_unix_only_date", function(value, tz) {
  if (!value) {
    return "";
  }
  if (!(value instanceof Date)) {
    value = fromUnixTime(value);
  }

  value = utcToZonedTime(value, tz);

  value.setHours(0);
  value.setMinutes(0);
  value.setSeconds(0);

  let only_date = tz_format(value, "yyyy-MM-dd", { timeZone: tz }); //there is some bug or some unknown with data-fns-tz package while converting to unix so this workaround is used.

  value = toDate(only_date, { timeZone: tz });

  let return_value = tz_format(value, "t", { timeZone: tz });
  return return_value;
});

Vue.filter("wpcal_unix_to_sw_sm_sd_if_fy", function(value, tz) {
  if (!value) {
    return "";
  }
  if (!(value instanceof Date)) {
    value = fromUnixTime(value);
  }

  value = utcToZonedTime(value, tz);

  let format_str = "iii, MMM d";
  let return_value =
    __(tz_format(value, "iii", { timeZone: tz }), "wpcal") +
    ", " +
    _x(
      tz_format(value, "MMM", { timeZone: tz }),
      "three-letter month abbreviation",
      "wpcal"
    ) +
    " " +
    tz_format(value, "d", { timeZone: tz }); //"iii, MMM d" -- MMM	is Jan, Feb, ..., Dec | iii is Mon, Tue, Wed, ..., Sun
  if (new Date().getFullYear() != value.getFullYear()) {
    format_str = "iii, MMM d, yyyy";
    return_value += tz_format(value, ", yyyy", { timeZone: tz });
  }

  //let return_value = tz_format(value, format_str, { timeZone: tz });
  return return_value;
});

Vue.filter("wpcal_unix_to_relative_day", function(value, tz) {
  if (!value) {
    return "";
  }
  if (!(value instanceof Date)) {
    value = fromUnixTime(value);
  }

  value = utcToZonedTime(value, tz);

  let today_zero = new Date();
  today_zero = utcToZonedTime(today_zero, tz);

  today_zero.setHours(0);
  today_zero.setMinutes(0);
  today_zero.setSeconds(0);
  today_zero.setMilliseconds(0);

  let hours_diff = differenceInHours(value, today_zero);

  let return_value = "";
  if (hours_diff > 48) {
    return return_value;
  }

  if (value >= today_zero) {
    if (hours_diff > -1 && hours_diff < 24) {
      return_value = T__("TODAY", "wpcal");
    } else if (hours_diff >= 24 && hours_diff < 48) {
      return_value = T__("TOMORROW", "wpcal");
    }
  } else {
    if (hours_diff < 0 && hours_diff >= -24) {
      return_value = T__("YESTERDAY", "wpcal");
    }
  }
  return return_value;
});

Vue.filter("wpcal_format_tz", function(tz) {
  if (!tz) {
    return "";
  }
  let tz_name = get_tz_name(tz);
  return tz_name;
});

Vue.filter("wpcal_format_weekdays_summary", function(selected_days) {
  let weekdays = weekdays_list;

  if (!Array.isArray(selected_days) || selected_days.length < 1) {
    return "";
  }
  let selected_days_final = [...selected_days];
  selected_days_final.sort();
  let show_days = [];
  let is_continuity = true;
  let last_index = "";

  for (let day_index of selected_days_final) {
    let weekday_detail_final = weekdays.find(weekday_detail => {
      return weekday_detail.index == day_index;
    });
    show_days.push(weekday_detail_final.short_day);
    if (last_index !== "") {
      if (
        is_continuity &&
        parseInt(last_index) + 1 != weekday_detail_final.index
      ) {
        is_continuity = false;
      }
    }
    last_index = day_index;
  }

  let days_str = show_days.join(", ");
  if (is_continuity && show_days.length > 1) {
    days_str = Tsprintf(
      /* translators: 1: Weekday abbreviation 2: Weekday abbreviation */
      T__("%1$s to %2$s", "wpcal"),
      show_days.shift(),
      show_days.pop()
    );
  }
  return days_str;
});

//------------------------------------------------------->

Vue.filter("wpcal_get_firstname", function(value) {
  if (!value && typeof value !== "string") {
    return "";
  }

  let return_value = value.split(" ")[0];
  return return_value;
});

Vue.filter("relationship_type_str", function(value) {
  if (value === "1to1") {
    return T__("One-on-One", "wpcal");
  }
  return value;
});

Vue.filter("mins_to_str", function(d) {
  d = Number(d);
  d = d * 60;
  let h = Math.floor(d / 3600);
  let m = Math.floor((d % 3600) / 60);
  //let s = Math.floor((d % 3600) % 60);

  let hDisplay = h > 0 ? h + " " + _n("hr", "hrs", h, "wpcal") : "";
  let mDisplay = m > 0 ? m + " " + _n("min", "mins", m, "wpcal") : "";
  //let sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";

  let display = [hDisplay, mDisplay];
  display = display.filter(function(v) {
    return v.trim() ? true : false;
  });
  return display.join(" "); // + sDisplay;
});

Vue.filter("calendar_provider_name", function(provider) {
  const providers = {
    google_calendar: "Google Calendar",
    office365_calendar: "Office 365",
    outlook_calendar: "Outlook Plug-in",
    icloud_calendar: "iCloud Calendar"
  };
  if (providers.hasOwnProperty(provider)) {
    return providers[provider];
  }
  return provider;
});

Vue.filter("tp_provider_name", function(provider) {
  const providers = {
    zoom_meeting: "Zoom",
    gotomeeting_meeting: "GoToMeeting",
    googlemeet_meeting: "Google Meet / Hangouts"
  };
  if (providers.hasOwnProperty(provider)) {
    return providers[provider];
  }
  return provider;
});

Vue.filter("locations_summary", function(locations) {
  if (!Array.isArray(locations)) {
    return locations;
  }
  if (!locations.length) {
    return "";
  }

  if (!locations[0].hasOwnProperty("type")) {
    return locations;
  }
  let total_locations = locations.length;
  let more_locations_count = total_locations - 1;

  let summary;
  if (
    locations[0].hasOwnProperty("form") &&
    locations[0].form &&
    locations[0].form.hasOwnProperty("location") &&
    locations[0].form.location
  ) {
    summary =
      '<span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 205px;" >' +
      locations[0].form.location +
      "</span>";
  } else {
    summary = location_prop_by_type(locations[0].type, "name");
  }
  if (more_locations_count > 0) {
    summary += " (+" + more_locations_count + " more)";
  }
  return summary;
});

Vue.filter("location_name_by_type", function(location_type) {
  return location_prop_by_type(location_type, "name");
});

Vue.filter("location_short_descr_by_type", function(location_type) {
  return location_prop_by_type(location_type, "short_descr");
});

Vue.filter("admin_booking_status", function(status, booking_to_time) {
  let current_time_ts = new Date().getTime() / 1000;
  let result = "Unknown";
  switch (true) {
    case status == 1 && booking_to_time >= current_time_ts:
      result = "Active";
      break;
    case status == 1 && booking_to_time < current_time_ts:
      result = "Past";
      break;
    case status == -1:
      result = "Cancelled";
      break;
    case status == -5:
      result = "Rescheduled";
      break;
  }

  return result;
});
