<template>
  <div class="tzs-cont">
    <v-select
      class="time-zone-v-selector"
      @input="check_and_set_tz($event)"
      label="name"
      :options="options"
      :value="tz_model"
      :clearable="false"
      :appendToBody="true"
      :calculate-position="with_popper"
      :reduce="name => name.tz"
    ></v-select>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import { createPopper } from "@popperjs/core";

import { timezone_list } from "@/utils/common_func.js";

export default {
  components: {
    vSelect
  },
  model: {
    prop: "form_tz",
    event: "changed-form-tz"
  },
  props: {
    use_as: {
      validator: function(value) {
        // The value must match one of these strings
        return ["view_in_tz", "form_tz"].indexOf(value) !== -1;
      },
      default: "view_in_tz"
    },
    form_tz: {
      type: String
    }
  },
  data() {
    return {
      options: timezone_list
    };
  },
  computed: {
    tz_model() {
      if (this.use_as === "form_tz") {
        return this.form_tz;
      }
      //if this.use_as === "view_in_tz"
      return this.tz;
    }
  },
  methods: {
    check_and_set_tz(tz) {
      if (this.use_as === "form_tz") {
        this.$emit("changed-form-tz", tz);
        return;
      }
      //if this.use_as === "view_in_tz"
      if (!tz) {
        return;
      }
      this.$store.dispatch("set_tz", tz);
    },
    with_popper(dropdownList, component, { width }) {
      //code taken from https://vue-select.org/guide/positioning.html#popper-js-integration
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width;
      dropdownList.style["z-index"] = 99000;
      dropdownList.classList.add("wpcal-v-select-body-dropdown"); //append to body class

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        //placement: this.placement,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -1]
            }
          },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              component.$el.classList.toggle(
                "drop-up",
                state.placement === "top"
              );
            }
          }
        ]
      });

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy();
    }
  }
};
</script>
<style scoped>
.tzs-cont {
  width: 300px;
}
.tzs-cont /deep/ .time-zone-v-selector .vs__dropdown-toggle {
  padding-bottom: 0;
  cursor: pointer;
  line-height: 1.2em;
  border: 1px solid #b1b6d1;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
}
#wpcal_admin_app
  .tzs-cont
  /deep/
  .time-zone-v-selector:not(.vs--open)
  .vs__dropdown-toggle:hover {
  background-color: #e4e9f5;
}
#wpcal-widget .widget-main .tzs-cont /deep/ .vs__dropdown-toggle {
  border: 1px solid #e9ecf2;
}
#wpcal-widget .widget-main .tzs-cont {
  margin: 10px 10px 20px 10px;
  width: auto;
}
.tzs-cont /deep/ .time-zone-v-selector .vs__dropdown-toggle .vs__actions {
  display: none;
}
.tzs-cont
  /deep/
  .time-zone-v-selector
  .vs__dropdown-toggle
  + .vs__dropdown-menu
  li {
  margin: 0;
  padding: 7px;
  font-size: 13px;
}
.tzs-cont /deep/ .vs__dropdown-option--highlight {
  background: #567bf3;
  color: #fff !important;
}
.tzs-cont /deep/ .time-zone-v-selector .vs__dropdown-toggle::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%23131336;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eicon-globe-clock%3C/title%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Ccircle class='cls-1' cx='17.25' cy='17.25' r='6'/%3E%3Cpolyline class='cls-1' points='19.9 17.25 17.25 17.25 17.25 14.6'/%3E%3Cpath class='cls-1' d='M10.5,23.15A11.25,11.25,0,1,1,23.15,10.51'/%3E%3Cpath class='cls-1' d='M9.29,22.92C7.77,20.69,6.75,16.63,6.75,12s1-8.69,2.54-10.92'/%3E%3Cline class='cls-1' x1='0.78' y1='11.25' x2='10.3' y2='11.25'/%3E%3Cline class='cls-1' x1='3' y1='5.25' x2='21' y2='5.25'/%3E%3Cline class='cls-1' x1='2.05' y1='17.25' x2='8.25' y2='17.25'/%3E%3Cpath class='cls-1' d='M14.71,1.08A16.92,16.92,0,0,1,17,8.22'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 7px;
}
.tzs-cont /deep/ .time-zone-v-selector::after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4.5 7.5'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%23131336;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eicon-back%3C/title%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cpolyline class='cls-1' points='3.75 0.75 0.75 3.75 3.75 6.75'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  position: absolute;
  width: 6px;
  height: 10px;
  transform: rotate(-90deg);
  top: 11px;
  right: 10px;
}
.tzs-cont /deep/ .time-zone-v-selector.vs--open.drop-up::after {
  transform: rotate(90deg);
}
.tzs-cont /deep/ .vs--single .vs__selected {
  margin: 5px 0;
  padding: 0;
  padding-left: 32px;
  font-size: 13px;
}
#wpcal-widget .widget-main .tzs-cont /deep/ .vs--single .vs__selected {
  margin: 5px 0;
}
.tzs-cont /deep/ .v-select {
  background-color: transparent;
  cursor: pointer;
}
.tzs-cont /deep/ .v-select .vs__search,
.tzs-cont /deep/ .v-select .vs__search:focus {
  border: 0 !important;
  background-color: transparent !important;
  z-index: 1;
}
.tzs-cont /deep/ .v-select .vs__search {
  cursor: pointer;
  font-size: 13px;
  padding: 0 8px;
  height: 30px;
  width: 10px;
  margin-top: 0;
  margin-bottom: 0;
  color: #131336 !important;
}
.tzs-cont /deep/ .time-zone-v-selector .v-select.vs--open .vs__search {
  padding: 0;
  margin-top: 0;
  padding-left: 33px;
  padding-right: 20px;
  cursor: text;
}
.tzs-cont /deep/ .vs--single.vs--open .vs__selected {
  margin: 7px 0;
}
.tzs-cont /deep/ .wpcal-v-select-body-dropdown li {
  margin: 0;
  font-family: "Rubik";
  font-size: 13px;
}
</style>
